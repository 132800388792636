import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { Button, ButtonGroup } from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import { getUrl } from "../../helpers/url";
import { updatePlanData } from "../../store/slices/subscriptionSlice";
import { fetchStatus } from "../../store/slices/preferenceSlice";
import {
  setShowSuccessModal,
  setShowErrorModal,
} from "../../store/slices/preferenceSlice";

function PostpaidBillingCards({ cmsInstance }) {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isDowngrading, setIsDowngrading] = useState(false);
  //const [customerKey, setCustomerKey] = useState("");
  //const [billingPortalLoading, setBillingPortalLoading] = useState(false);

  // subscription states
  const {
    cardsData,
    subscription: { planData },
  } = useSelector((state) => state.subscription);

  /**
   * @description This function is responsible for opening new window for adding card in stripe.
   */
  const openNewWindow = () => {
    window.addEventListener("message", (e) => {
      if (e.data?.paymentStatus) {
        console.log(e.data?.paymentStatus);
        setPaymentStatus(e.data?.paymentStatus);
      }
    });
  };

  const onUpdateSubscription = async ({ plan }) => {
    try {
      setIsUpdating(true);
      const shop = searchParams.get("shop");

      // Create Checkout
      const { data: res } = await axios.post(
        getUrl("REACT_APP_CREATE_SUBSCRIPTION"),
        {
          shop: shop,
          appSlug: "shopifyFortnox",
          returnUrl: `${window.location.origin}/redirect?shop=${shop}&paymentStatus=cardAdded`,
          plan: plan,
        }
      );

      if (res) {
        // redirects to the stripe window
        window.open(res, "_blank");
      }
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
    } finally {
      setIsUpdating(false);
    }
  };

  /**
   * This function is used for cancelling the subscription
   */
  const onCancelSubscription = async ({ plan }) => {
    try {
      if (plan === "basic") {
        setIsDowngrading(true);
      } else {
        setIsCancelling(true);
      }

      const shop = searchParams.get("shop");
      await axios.post(getUrl("REACT_APP_CANCEL_SUBSCRIPTION"), {
        shop,
        plan: "premium",
      });

      dispatch(
        setShowSuccessModal({
          message:
            cmsInstance &&
            cmsInstance.sendData().cmsContent.notifications.subscription
              .successContent.cancelSubscription.message,
        })
      );
      dispatch(fetchStatus({ shop }));
      dispatch(
        updatePlanData({
          plan: "basic",
          subscriptionId: false,
        })
      );
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
    } finally {
      if (plan === "basic") {
        setIsDowngrading(false);
      } else {
        setIsCancelling(false);
      }
    }
  };

  /**
   * @description This function is used for checking payment status wh
   */
  const paymentStatusCheck = async () => {
    const shop = searchParams.get("shop");
    if (paymentStatus === "cardAdded") {
      dispatch(updatePlanData({ plan: "premium", subscriptionId: true }));
      navigate(`..${window.location.pathname}?shop=${shop}&paymentStatus=none`);

      dispatch(
        setShowSuccessModal({
          message:
            cmsInstance &&
            cmsInstance.sendData().cmsContent.notifications.subscription
              .successContent.upgradePlan.message,
        })
      );
    } else if (paymentStatus === "failed") {
      dispatch(
        setShowErrorModal({
          message:
            cmsInstance.sendData().cmsContent.notifications.subscription
              .failContent.paymentFailed.message,
        })
      );
    }
    setPaymentStatus("");
  };

  const renderButtons = (card) => {
    if (planData.plan === "basic" && card.name === "Free Trial") {
      return (
        <div className="mt-2">
          {planData.usageCount >= 15 ? (
            <Button className="pricing-selected-btn" disabled fullWidth>
              Trial Expired
            </Button>
          ) : (
            <Button className="pricing-selected-btn" disabled fullWidth>
              Selected
            </Button>
          )}
        </div>
      );
    } else if (planData.plan === "basic" && card.name === "Premium") {
      return (
        <div className="mt-2">
          <Button
            className="pricing-upgrade-btn"
            disabled={isUpdating}
            onClick={() => onUpdateSubscription({ plan: "premium" })}
            fullWidth
            loading={isUpdating}
            primary
          >
            Upgrade
          </Button>
        </div>
      );
    } else if (planData.plan === "premium" && card.name === "Premium") {
      return (
        <ButtonGroup fullWidth>
          <div classsName="mt-2">
            <div>
              <Button className="pricing-selected-btn" disabled>
                Selected
              </Button>
            </div>
            <div className="mt-2">
              <Button
                className="pricing-cancel-btn mt-2"
                disabled={isCancelling}
                onClick={() => onCancelSubscription({ plan: "premium" })}
                destructive
                loading={isCancelling}
              >
                Cancel Subscription
              </Button>
            </div>
          </div>
        </ButtonGroup>
      );
    } else if (
      planData.plan === "premium-cancelled" &&
      card.name === "Premium"
    ) {
      return (
        <div className="mt-2">
          <Button
            className="pricing-upgrade-btn"
            disabled={isUpdating}
            onClick={() => onUpdateSubscription({ plan: "premium" })}
            primary
            loading={isUpdating}
            fullWidth
          >
            Upgrade
          </Button>
        </div>
      );
    } else if (planData.plan === "premium" && card.name === "Free Trial") {
      return (
        <div className="mt-2">
          <Button
            disabled={isDowngrading}
            onClick={() => onCancelSubscription({ plan: "basic" })}
            primary
            loading={isDowngrading}
            fullWidth
          >
            Downgrade
          </Button>
        </div>
      );
    }
  };

  /**
   * This function is used for conditionally rendering the text
   */
  const renderText = () => {
    if (planData.plan === "basic") {
      if (planData.usageCount >= 15) {
        return "The trial period of this app has expired. To continue using it, please upgrade to the premium plan.";
      } else {
        return "You are currently subscribed to the Basic plan.";
      }
    } else if (planData.plan === "premium") {
      return "You are currently subscribed to the Premium plan.";
    } else if (planData.plan === "premium-cancelled") {
      return "Your Premium plan has been cancelled. Please upgrade to Premium to continue using the app";
    }
  };

  useEffect(() => {
    openNewWindow();
    if (paymentStatus) {
      paymentStatusCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus]);

  return (
    <div>
      {window.location.pathname !== "/setup" && (
        <Alert variant="light" style={{ border: "1px solid #dcdcdc" }}>
          <div className="d-flex flex-row justify-content-left">
            <p>{renderText()}</p>
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center">
            <p className="mb-0">
              Sync Usage:{" "}
              <b>
                {planData.plan === "basic" && planData.usageCount >= 15
                  ? 15
                  : planData.usageCount}
              </b>
            </p>
            {/* {planData.customerKey && (
            <Button
              className="sync-settings-button"
              disabled={billingPortalLoading}
              // onClick={() => onOpenStripeCustomerPortal()}
            >
              {billingPortalLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>
                  View and manage billing details{" "}
                  <FaExternalLinkAlt style={{ marginTop: "-3px" }} />
                </span>
              )}
            </Button>
          )} */}
          </div>
        </Alert>
      )}

      <Row className="pricing-cards-container">
        {cardsData &&
          cardsData.map((card) => (
            <Col
              key={card.id}
              sm={8}
              lg={5}
              className="pricing-card px-3 py-3 my-3"
            >
              <div className="text-center">
                {cmsInstance &&
                  cmsInstance.showRichText(
                    `cmsContent.subscription.${card.id}.heading`,
                    "mb-1"
                  )}
                {cmsInstance &&
                  cmsInstance.showRichText(
                    `cmsContent.subscription.${card.id}.description`
                  )}
                {cmsInstance &&
                  cmsInstance.showRichText(
                    `cmsContent.subscription.${card.id}.price`
                  )}
              </div>
              <div className="my-3">{renderButtons(card)}</div>
              {cmsInstance &&
                cmsInstance.showRichText(
                  `cmsContent.subscription.${card.id}.points`
                )}
            </Col>
          ))}
      </Row>
    </div>
  );
}

export default PostpaidBillingCards;
