import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";

import { onChangeInvoiceActiveTab } from "../store/slices/preferenceSlice";
import { clearDefaultValues } from "../store/slices/mappingSlice";

import SyncRules from "../components/invoices/SyncRules";
import FieldMappings from "../components/invoices/FieldMappings";

const InvoiceSync = () => {
  const invoiceTabKeys = Object.freeze({
    syncRules: "syncRules",
    fieldMappings: "fieldMappings",
  });

  const dispatch = useDispatch();

  // preference states
  const { invoiceActiveTab } = useSelector((state) => state.preference);

  // mapping states
  const {
    isMappingsSaved: { invoice: isInvoiceMappingsSaved },
    shopify: { loading: shopifyLoading },
    fortnox: { loading: fortnoxLoading },
  } = useSelector((state) => state.mapping);

  // invoice states
  const { isInvoiceSyncRulesSaved } = useSelector((state) => state.invoice);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Tabs
      activeKey={invoiceActiveTab}
      onSelect={(selectedKey) => {
        window.scrollTo(0, 0);
        dispatch(clearDefaultValues());
        dispatch(
          onChangeInvoiceActiveTab({
            selectedTab: selectedKey,
            isInvoiceSyncRulesSaved,
            isInvoiceMappingsSaved,
            shopifyLoading,
            fortnoxLoading,
          })
        );
      }}
      className="nested-sync-tabs mt-4"
    >
      <Tab eventKey={invoiceTabKeys.syncRules} title="Invoice sync rules">
        <SyncRules />
      </Tab>
      <Tab
        eventKey={invoiceTabKeys.fieldMappings}
        title="Invoice field mappings"
      >
        <FieldMappings />
      </Tab>
    </Tabs>
  );
};

export default InvoiceSync;
