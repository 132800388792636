import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GoSearch } from "react-icons/go";

import { setShopifyPreference } from "../../store/slices/mappingSlice";

const MappingDropdown = ({
  mappedItem,
  mappings,
  shopifyDropdownFields,
  mappingsFor,
  shopifyCustomerFields,
}) => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [customerSearch, setCustomerSearch] = useState([]);

  // mapping states
  const {
    selectShopifyPreference,
    mappings: {
      defaultMappings: { products: productMappings },
    },
  } = useSelector((state) => state.mapping);

  const dropdownToggleText = () => {
    if (selectShopifyPreference !== undefined) {
      return selectShopifyPreference.HRF;
    } else if (!selectShopifyPreference && !mappedItem.SH_FieldName_HRF) {
      return "Select";
    } else {
      return mappedItem.SH_FieldName_HRF;
    }
  };

  const onSearchFields = (enteredInput) => {
    setSearchInput(enteredInput);
    if (mappingsFor === "invoice") {
      const searchArray = shopifyDropdownFields.filter((fieldItem) => {
        return fieldItem.HRF.toLowerCase().includes(enteredInput.toLowerCase());
      });
      setSearchResults(searchArray);
      const res = shopifyCustomerFields.filter((field) => {
        return field.HRF.toLowerCase().includes(enteredInput.toLowerCase());
      });
      setCustomerSearch(res);
    } else {
      const searchArray = filterShopifyFields().filter((fieldItem) => {
        return fieldItem.HRF.toLowerCase().includes(enteredInput.toLowerCase());
      });
      setSearchResults(searchArray);
    }
  };

  const checkDisabledField = (fieldName) => {
    const isFound = (
      mappingsFor === "products" ? productMappings : mappings
    ).find((mappedItem) => {
      if (mappedItem.SH_FieldName_HRF) {
        return mappedItem.SH_FieldName_HRF === fieldName;
      }
      return null;
    });

    if (isFound) {
      return mappedItem.SH_FieldName_HRF !== fieldName;
    }
    return isFound;
  };

  const filterShopifyFields = () => {
    if (mappingsFor === "customer") {
      return shopifyDropdownFields.filter((field) => field.CRF !== "doNotAdd");
    } else {
      return shopifyDropdownFields;
    }
  };

  return (
    <Dropdown className="dropdown">
      <Dropdown.Toggle className="dropdown-box">
        {dropdownToggleText()}
      </Dropdown.Toggle>

      <Dropdown.Menu renderOnMount={true} popperConfig={{ strategy: "fixed" }}>
        <div className="p-2">
          <div className="dropdown-search-container px-2">
            <span>
              <GoSearch size={20} className="me-2" />
            </span>
            <input
              type="search"
              value={searchInput}
              placeholder="Search"
              onChange={(e) => onSearchFields(e.target.value)}
              className="search-input"
            />
          </div>
          <hr />
          {mappingsFor === "invoice" ? (
            <div className="dropdown-menu-container">
              {shopifyDropdownFields && shopifyCustomerFields && (
                <>
                  <Dropdown.ItemText>
                    <b>Order</b>
                  </Dropdown.ItemText>
                  {searchInput.length > 0 && searchResults.length === 0 && (
                    <div className="d-flex flex-row justify-content-center">
                      <b>No results found</b>
                    </div>
                  )}
                  {(searchInput.length > 0
                    ? searchResults
                    : shopifyDropdownFields
                  ).map((fieldItem, index) => (
                    <Dropdown.Item
                      className="dropdown-item ms-2"
                      key={index}
                      disabled={checkDisabledField(fieldItem.HRF)}
                      onClick={() =>
                        dispatch(
                          setShopifyPreference({
                            selectedOption: fieldItem,
                          })
                        )
                      }
                    >
                      {fieldItem.HRF}
                    </Dropdown.Item>
                  ))}

                  <Dropdown.ItemText className="mt-2">
                    <b>Customer</b>
                  </Dropdown.ItemText>
                  {searchInput.length > 0 && customerSearch.length === 0 && (
                    <div className="d-flex flex-row justify-content-center">
                      <b>No results found</b>
                    </div>
                  )}
                  {(searchInput.length > 0
                    ? customerSearch
                    : shopifyCustomerFields
                  ).map((fieldItem, index) => (
                    <Dropdown.Item
                      className="dropdown-item ms-2"
                      key={index}
                      disabled={checkDisabledField(fieldItem.HRF)}
                      onClick={() =>
                        dispatch(
                          setShopifyPreference({
                            selectedOption: fieldItem,
                          })
                        )
                      }
                    >
                      {fieldItem.HRF}
                    </Dropdown.Item>
                  ))}
                </>
              )}
            </div>
          ) : (
            <div className="dropdown-menu-container">
              {searchInput.length > 0 && searchResults.length === 0 && (
                <div className="d-flex flex-row justify-content-center">
                  <b>No results found</b>
                </div>
              )}

              {filterShopifyFields() && filterShopifyFields().length > 0 ? (
                (searchInput.length > 0
                  ? searchResults
                  : filterShopifyFields()
                ).map((fieldItem, index) => (
                  <Dropdown.Item
                    key={index}
                    disabled={checkDisabledField(fieldItem.HRF)}
                    className="dropdown-item"
                    onClick={() =>
                      dispatch(
                        setShopifyPreference({
                          selectedOption: fieldItem,
                        })
                      )
                    }
                  >
                    {fieldItem.HRF}
                  </Dropdown.Item>
                ))
              ) : (
                <div className="d-flex flex-row justify-content-center">
                  <b>No fields found</b>
                </div>
              )}
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MappingDropdown;
