import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  onChangeSaveModalPreference,
  onChangeTabValues,
  fetchStatus,
} from "../../store/slices/preferenceSlice";
import { checkIsMappingsSaved } from "../../store/slices/mappingSlice";

import { onSaveContactSyncRules } from "../contacts/SyncRules";
import {
  onSaveInvoiceSyncRules,
  checkCountForProductMapped,
} from "../invoices/SyncRules";
import { onSaveDefaultMappings } from "../contacts/DefaultMappings";
import { onSaveMappings } from "../mapping/MappingWindow";
import { onSaveProductSyncRules } from "../products/SyncRules";

import { useCmsHook } from "../../helpers/cmsHook";

const AutoSaveModal = () => {
  const { cmsInstance } = useCmsHook();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  // preference states
  const {
    saveModalPreference,
    settingsActiveTab,
    contactsActiveTab,
    invoiceActiveTab,
    productActiveTab,
  } = useSelector((state) => state.preference);

  // contact states
  const {
    isContactsSyncRulesSaved,
    isContactDefaultMappingsSaved,
    fortnoxFilterPreference,
    shopifyFilterPreference,
    shopifyCustomerNumberPreference,
    shopifyCustomerPreference,
    paymentTermPreference,
    currencyPreference,
    priceListPreference,
    deliveryWaysPreference,
    deliveryTermsPreference,
    vatTypesPreference,
    languagesPreference,
  } = useSelector((state) => state.contact);

  // invoice states
  const {
    isInvoiceSyncRulesSaved,
    preferenceForStages,
    preferenceForInvoice,
    shippingDischargePreference,
    discount: { shippingDischarge },
  } = useSelector((state) => state.invoice);

  // product states
  const {
    preferenceForProducts,
    defaultProductPreference,
    preferenceForCreatingProduct,
    defaultProductForCreating,
    preferenceForSearchParameters,
    shopifyProductFilterPreference,
    fortnoxProductFilterPreference,
    isProductSyncRulesSaved,
  } = useSelector((state) => state.product);

  // mapping states
  const {
    isMappingsSaved,
    mappings: { defaultMappings },
  } = useSelector((state) => state.mapping);

  const onSaveUnsavedFields = async () => {
    if (settingsActiveTab === "contact") {
      if (contactsActiveTab === "syncRules" && !isContactsSyncRulesSaved) {
        await onSaveContactSyncRules({
          setIsLoading,
          searchParams,
          fortnoxFilterPreference,
          shopifyFilterPreference,
          shopifyCustomerNumberPreference,
          shopifyCustomerPreference,
          dispatch,
          cmsInstance,
        });
      } else if (
        contactsActiveTab === "defaultMappings" &&
        !isContactDefaultMappingsSaved
      ) {
        await onSaveDefaultMappings({
          setIsLoading,
          searchParams,
          paymentTermPreference,
          currencyPreference,
          priceListPreference,
          deliveryWaysPreference,
          deliveryTermsPreference,
          vatTypesPreference,
          languagesPreference,
          dispatch,
          cmsInstance,
        });
      } else if (
        contactsActiveTab === "fieldMappings" &&
        !isMappingsSaved.customer
      ) {
        await onSaveMappings({
          setIsLoading,
          searchParams,
          mappings: defaultMappings.customer,
          mappingsFor: "customer",
          dispatch,
          cmsInstance,
          notificationPath: "contactFieldMappings",
        });
      }
    } else if (settingsActiveTab === "invoice") {
      if (invoiceActiveTab === "syncRules" && !isInvoiceSyncRulesSaved) {
        await onSaveInvoiceSyncRules({
          setIsLoading,
          searchParams,
          dispatch,
          cmsInstance,
          preferenceForStages,
          preferenceForInvoice,
          shippingDischarge,
          shippingDischargePreference,
        });
      } else if (
        invoiceActiveTab === "fieldMappings" &&
        !isMappingsSaved.invoice
      ) {
        await onSaveMappings({
          setIsLoading,
          searchParams,
          mappings: defaultMappings.invoice,
          mappingsFor: "invoice",
          dispatch,
          cmsInstance,
          notificationPath: "invoiceFieldMappings",
        });
      }
    } else if (settingsActiveTab === "product") {
      if (productActiveTab === "syncRules" && !isProductSyncRulesSaved) {
        await onSaveProductSyncRules({
          setIsLoading,
          searchParams,
          dispatch,
          preferenceForProducts,
          defaultProductPreference,
          preferenceForCreatingProduct,
          defaultProductForCreating,
          preferenceForSearchParameters,
          shopifyProductFilterPreference,
          fortnoxProductFilterPreference,
          cmsInstance,
        });
        if (
          !isMappingsSaved.products &&
          preferenceForSearchParameters === "setCustomMappings" &&
          checkCountForProductMapped({ mappings: defaultMappings.products }) > 0
        ) {
          await onSaveMappings({
            dispatch,
            setIsLoading,
            searchParams,
            mappings: defaultMappings.products,
            mappingsFor: "products",
            notificationPath: "productFieldMappings",
            cmsInstance,
          });
        }
      } else if (
        productActiveTab === "fieldMappings" &&
        !isMappingsSaved.productFieldMappings
      ) {
        await onSaveMappings({
          mappings: defaultMappings.productFieldMappings,
          setIsLoading,
          searchParams,
          dispatch,
          mappingsFor: "productFieldMappings",
          notificationPath: "productFieldMappings",
        });
      }
    }
    dispatch(onChangeSaveModalPreference({ show: false }));
    dispatch(onChangeTabValues());
  };

  const onDiscardChanges = () => {
    const shop = searchParams.get("shop");

    dispatch(fetchStatus({ shop }));
    dispatch(checkIsMappingsSaved({ mappingsFor: "customer" }));
    dispatch(checkIsMappingsSaved({ mappingsFor: "invoice" }));
    dispatch(checkIsMappingsSaved({ mappingsFor: "products" }));
    dispatch(checkIsMappingsSaved({ mappingsFor: "productFieldMappings" }));
    dispatch(onChangeSaveModalPreference({ show: false }));
    dispatch(onChangeTabValues());
  };

  const renderButtons = () => {
    if (
      settingsActiveTab === "invoice" &&
      invoiceActiveTab === "syncRules" &&
      shippingDischarge &&
      !shippingDischargePreference
    ) {
      return (
        <Button
          className="auto-save-btn"
          onClick={() => {
            dispatch(onChangeSaveModalPreference({ show: false }));
            window.scrollTo(0, 100);
          }}
        >
          Edit
        </Button>
      );
    } else if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      preferenceForSearchParameters === "setCustomMappings" &&
      checkCountForProductMapped({ mappings: defaultMappings.products }) === 0
    ) {
      return (
        <Button
          className="auto-save-btn"
          onClick={() => {
            dispatch(onChangeSaveModalPreference({ show: false }));
            window.scrollTo(0, 100);
          }}
        >
          Edit
        </Button>
      );
    } else if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      ((preferenceForProducts === "useDefaultProduct" &&
        !defaultProductPreference) ||
        (preferenceForProducts === "findCreateProduct" &&
          preferenceForCreatingProduct &&
          !defaultProductForCreating))
    ) {
      return (
        <Button
          className="auto-save-btn"
          onClick={() => {
            dispatch(onChangeSaveModalPreference({ show: false }));
            window.scrollTo(0, document.body.scrollHeight);
          }}
        >
          Edit
        </Button>
      );
    } else if (
      settingsActiveTab === "contact" &&
      contactsActiveTab === "syncRules" &&
      shopifyCustomerNumberPreference.value === "shopify" &&
      !shopifyCustomerPreference
    ) {
      return (
        <Button
          className="auto-save-btn"
          onClick={() => {
            dispatch(onChangeSaveModalPreference({ show: false }));
            window.scrollTo(0, document.body.scrollHeight);
          }}
        >
          Edit
        </Button>
      );
    } else {
      return (
        <>
          <Button
            className="discard-button me-3"
            onClick={() => onDiscardChanges()}
          >
            Discard
          </Button>
          <Button
            className="auto-save-btn"
            onClick={() => onSaveUnsavedFields()}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Save changes</span>
            )}
          </Button>
        </>
      );
    }
  };

  const onRenderContent = () => {
    if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      preferenceForSearchParameters === "setCustomMappings" &&
      checkCountForProductMapped({ mappings: defaultMappings.products }) === 0
    ) {
      return (
        <div className="auto-save-modal">
          <p>
            {cmsInstance &&
              cmsInstance.sendData().cmsContent.notifications.autoSave
                .successContent.productMappings.message}
          </p>
        </div>
      );
    } else if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      ((preferenceForProducts === "useDefaultProduct" &&
        !defaultProductPreference) ||
        (preferenceForProducts === "findCreateProduct" &&
          preferenceForCreatingProduct &&
          !defaultProductForCreating))
    ) {
      return (
        <div className="auto-save-modal">
          <p className="rules-description">
            {cmsInstance &&
              cmsInstance.sendData().cmsContent.notifications.autoSave
                .successContent.defaultProduct.message}
          </p>
        </div>
      );
    } else if (
      settingsActiveTab === "invoice" &&
      invoiceActiveTab === "syncRules" &&
      shippingDischarge &&
      !shippingDischargePreference
    ) {
      return (
        <div className="auto-save-modal">
          <p className="rules-description">
            {cmsInstance &&
              cmsInstance.sendData().cmsContent.notifications.autoSave
                .successContent.orderAndShipping.message}
          </p>
        </div>
      );
    } else if (
      settingsActiveTab === "contact" &&
      contactsActiveTab === "syncRules" &&
      shopifyCustomerNumberPreference.value === "shopify" &&
      !shopifyCustomerPreference
    ) {
      return (
        <div className="auto-save-modal">
          <p className="rules-description">
            {cmsInstance &&
              cmsInstance.sendData().cmsContent.notifications.autoSave
                .successContent.msbcCustomerNumber.message}
          </p>
        </div>
      );
    } else {
      return (
        <div className="auto-save-modal">
          <p className="rules-description">
            {cmsInstance &&
              cmsInstance.sendData().cmsContent.notifications.autoSave
                .successContent.unsavedChanges.message}
          </p>
        </div>
      );
    }
  };
  return (
    <Modal
      show={saveModalPreference}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>{onRenderContent()}</Modal.Body>
      <Modal.Footer>{renderButtons()}</Modal.Footer>
    </Modal>
  );
};

export default AutoSaveModal;
