import React from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { Button, ButtonGroup } from "@shopify/polaris";
import { useState } from "react";
import axios from "axios";

import { getUrl } from "../../helpers/url";

import { useDispatch } from "react-redux";
import { fetchShopifyFields } from "../../store/slices/mappingSlice";

import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../../store/slices/preferenceSlice";

import { useSearchParams } from "react-router-dom";

const CreateNewProperty = ({ mappingsFor }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [type, setType] = useState("single_line_text_field");
  const [ownerType, setOwnerType] = useState(
    mappingsFor === "productFieldMappings"
      ? "PRODUCT"
      : mappingsFor === "invoice"
      ? "ORDER"
      : "CUSTOMER"
  );

  const [searchParams] = useSearchParams();

  const handleShow = () => setShow(true);

  const clearValues = () => {
    setName("");
    setKey("");
    setType("single_line_text_field");
    setOwnerType(
      mappingsFor === "productFieldMappings"
        ? "PRODUCT"
        : mappingsFor === "invoice"
        ? "ORDER"
        : "CUSTOMER"
    );
  };

  const onCreateShopifyProperty = async () => {
    try {
      setLoading(true);
      const shop = searchParams.get("shop");
      const data = {
        shop,
        name,
        key,
        type,
        ownerType,
      };

      await axios.post(getUrl("REACT_APP_CREATE_PROPERTY"), data);

      dispatch(fetchShopifyFields({ shop }));
      dispatch(
        setShowSuccessModal({
          message: "Shopify field created successfully.",
        })
      );
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
    } finally {
      setLoading(false);
      clearValues();
    }
  };

  return (
    <div>
      <Button className="sidebar-button" onClick={handleShow}>
        Create new Shopify field
      </Button>
      <Offcanvas
        show={show}
        placement="end"
        onHide={() => {
          setShow(false);
          clearValues();
        }}
      >
        <Offcanvas.Header className="sidebar-header" closeButton>
          <Offcanvas.Title className="sidebar-heading">
            Create new field
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="">
            <div>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>

            <div className="mt-4">
              <Form.Label>Key</Form.Label>
              <Form.Control
                type="text"
                placeholder="key"
                onChange={(e) => setKey(e.target.value)}
                value={key}
              />
              <Form.Text
                style={{
                  color: "#192435",
                  fontFamily: "Inter",
                }}
              >
                * Special characters are not allowed
              </Form.Text>
            </div>

            <div className="mt-4">
              <label className="mb-2">Type</label>
              <Form.Select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="single_line_text_field">single line text</option>
                <option value="number_integer">integer</option>
              </Form.Select>
            </div>
            <div className="mt-4">
              <label className="mb-2">Field type</label>
              <Form.Select
                value={ownerType}
                onChange={(e) => setOwnerType(e.target.value)}
              >
                <option
                  value={
                    mappingsFor === "productFieldMappings"
                      ? "PRODUCT"
                      : "CUSTOMER"
                  }
                >
                  {mappingsFor === "productFieldMappings"
                    ? "Product"
                    : "Customer"}
                </option>
                <option
                  value={
                    mappingsFor === "productFieldMappings"
                      ? "PRODUCTVARIANT"
                      : "ORDER"
                  }
                >
                  {mappingsFor === "productFieldMappings" ? "Variant" : "Order"}
                </option>
              </Form.Select>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="sidebar-footer py-2 pe-3 d-flex justify-content-end">
          <div className="me-3">
            <ButtonGroup>
              <div className="me-3">
                <Button
                  className="me-3 cancel-btn"
                  destructive
                  onClick={() => {
                    setShow(false);
                    clearValues();
                  }}
                >
                  Cancel
                </Button>
              </div>
              <Button
                className="me-3 create-btn"
                primary
                onClick={onCreateShopifyProperty}
                disabled={
                  name === "" ||
                  name.startsWith(" ") ||
                  key === "" ||
                  key.startsWith(" ")
                }
                loading={loading}
              >
                Create
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};

export default CreateNewProperty;
