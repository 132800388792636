import { useState, useEffect } from "react";
import { Container, Col, Card, Spinner } from "react-bootstrap";
import { Button } from "@shopify/polaris";
import { AiOutlinePlus } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import shopifyLogo from "../assets/images/shopify-logo.svg";
import fortnoxLogo from "../assets/images/logo-fortnox.svg";

import {
  fetchStatus,
  onChangeFortnoxConnected,
  setShowErrorModal,
  setShowSuccessModal,
} from "../store/slices/preferenceSlice";

import { getUrl } from "../helpers/url";

import { useCmsHook } from "../helpers/cmsHook";
import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

const ConnectFortnox = () => {
  const { cmsInstance, setCmsInstance } = useCmsHook();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [disconnectLoading, setDisConnectLoading] = useState(false);
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { isFortnoxConnected } = useSelector((state) => state.preference);

  const connectFortnox = () => {
    const url = `https://apps.fortnox.se/oauth-v1/auth?client_id=${process.env.REACT_APP_FORTNOX_CLIENT_ID}&redirect_uri=${window.location.origin}/setup&scope=profile%20customer%20currency%20invoice%20article%20settings%20price%20order&state=somestate123&response_type=code&access_type=offline`;
    const params = `toolbar=no, menubar=no, width=1000, height=700, top=100, left=250`;
    const ref = window.open(url, "_blank", params);
    ref.opener = null;
    const reference = setInterval(() => {
      const queryString = ref.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get("code");
      if (code) {
        setCode(code);
        clearInterval(reference);
        ref.close();
      }
    }, 1000);
  };

  const storeFortnoxCreds = async () => {
    try {
      setIsLoading(true);
      const shop = searchParams.get("shop");
      const response = await axios.get(getUrl("REACT_APP_CONNECT_FORTNOX"), {
        params: {
          code: code,
          shop: shop,
        },
      });

      if (response.status === 200) {
        dispatch(onChangeFortnoxConnected({ isConnected: true }));
      }
      dispatch(
        setShowSuccessModal({
          message:
            cmsInstance.sendData().cmsContent.notifications.authentication
              .successContent.connectEconomic.message,
        })
      );
      return response;
    } catch (error) {
      console.error(error);
      let errorMessage;
      if (error.response && error.response?.data?.message)
        errorMessage = error.reponse.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const disConnectFortnox = async () => {
    try {
      setDisConnectLoading(true);
      const shop = searchParams.get("shop");

      await axios.post(getUrl("REACT_APP_DISCONNECT_FORTNOX"), {
        shop,
      });
      dispatch(onChangeFortnoxConnected({ isConnected: false }));

      dispatch(
        setShowSuccessModal({
          message:
            cmsInstance.sendData().cmsContent.notifications.authentication
              .successContent.disconnectEconomic.message,
        })
      );
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
    } finally {
      setDisConnectLoading(false);
    }
  };

  useEffect(() => {
    const shop = searchParams.get("shop");
    if (shop) {
      dispatch(fetchStatus({ shop }));
    }
    if (code && shop) {
      storeFortnoxCreds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (window.location.pathname === "/connect-fortnox") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor(dispatch);
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const shop = searchParams.get("shop");
    if (shop) {
      dispatch(fetchStatus({ shop }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-container">
      {isLoading || !cmsInstance ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <>
          {window.location.pathname === "/connect-msbc" && (
            <div>
              <CmsEditAndSave />
            </div>
          )}
          <Container
            className="d-flex flex-row justify-content-center align-items-center"
            style={{
              minHeight: "100vh",
            }}
          >
            <Col lg={7} className="my-4">
              <Card>
                <div className="w-100 authentication-card d-flex flex-column align-items-center py-3">
                  {cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.authentication.heading",
                      "my-4"
                    )}
                  <div className="d-flex flex-row align-items-center justify-content-center my-4">
                    <div className="logo-container">
                      <Card.Img
                        src={shopifyLogo}
                        alt="shopify-logo"
                        className="logo"
                      />
                    </div>
                    <div>
                      <AiOutlinePlus size={17} className="plus-icon mx-3" />
                    </div>
                    <div className="logo-container">
                      <Card.Img
                        src={fortnoxLogo}
                        alt="fortnox-logo"
                        className="logo"
                      />
                    </div>
                  </div>

                  {cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.authentication.description1",
                      "w-50 authentication-para"
                    )}

                  {isFortnoxConnected &&
                    cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.authentication.description2.connected",
                      "text-center w-50"
                    )}
                  {!isFortnoxConnected &&
                    cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.authentication.description2.disconnected",
                      "text-center w-50 my-2"
                    )}
                  <div className="d-flex mb-3 d-flex flex-row">
                    <div>
                      <Button
                        onClick={() => {
                          connectFortnox();
                        }}
                        className="connect-button"
                        disabled={isFortnoxConnected}
                        primary
                        fullWidth
                      >
                        {isFortnoxConnected
                          ? "Fortnox connected"
                          : "Connect Fortnox"}
                      </Button>
                    </div>
                    <div className="ms-3">
                      {isFortnoxConnected && (
                        <Button
                          onClick={disConnectFortnox}
                          className="disconnect-button mt-2"
                          loading={disconnectLoading}
                          destructive
                        >
                          Disconnect Fortnox
                        </Button>
                      )}
                    </div>
                  </div>

                  {cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.authentication.description3",
                      "text-center w-50"
                    )}
                  <p className="w-50 text-center mt-3">
                    Here's the{" "}
                    <a href="/setup-guide" target="_blank">
                      link
                    </a>{" "}
                    to the setup guide
                  </p>
                </div>
              </Card>
            </Col>
          </Container>
        </>
      )}
    </div>
  );
};

export default ConnectFortnox;
