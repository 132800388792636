import React from "react";

const SearchResultsNotFound = () => {
  return (
    <div className="search-not-found-container p-5">
      <div className="text-center">
        <img
          src="https://static.hsappstatic.net/ui-images/static-2.422/optimized/empty-state-charts.svg"
          alt="Empty State Chart"
        />
        <p className="mt-2">No mappings found.</p>
        <p className="hint">Try changing your filters.</p>
      </div>
    </div>
  );
};

export default SearchResultsNotFound;
