import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchStatus, setShowErrorModal } from "./preferenceSlice";

import axios from "axios";

import { getUrl } from "../../helpers/url";

const initialState = {
  shopifyCustomerNumberPreference: {
    value: "shopify",
  },
  isContactsSyncRulesSaved: false,
  isContactDefaultMappingsSaved: false,
  fortnoxFilterPreference: undefined,
  shopifyFilterPreference: undefined,
  fortnoxCustomerPreference: {
    name: "Customer Number",
    value: "customerNumber",
  },
  shopifyCustomerPreference: undefined,
  fortnoxCustomerDropdownFields: [
    {
      CRF: "customerNumber",
      HRF: "Customer Number",
    },
  ],
  shopifyCustomerNumberFields: [
    {
      title: "Let Fortnox create customer number",
      value: "fortnox",
    },
    {
      title: "Take customer number from Shopify",
      value: "shopify",
    },
  ],
  paymentTermPreference: undefined,
  currencyPreference: undefined,
  priceListPreference: undefined,
  deliveryWaysPreference: undefined,
  deliveryTermsPreference: undefined,
  vatTypesPreference: undefined,
  languagesPreference: undefined,
  fortnoxDefaultMappings: {
    loading: false,
    fieldsData: {},
    error: "",
  },
};

export const getFortnoxDefaultMappings = createAsyncThunk(
  "contact/getFortnoxDefaultMappings",
  async ({ shop }, { dispatch }) => {
    try {
      const { data: response } = await axios.get(
        getUrl("REACT_APP_GET_FORTNOX_FIELDS"),
        {
          params: {
            shop,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
      throw error;
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    onChangeShopifyCustomerNumber(state, { payload }) {
      state.shopifyCustomerNumberPreference = {
        value: payload.selectedOption,
      };
      if (state.shopifyCustomerNumberPreference.value === "shopify") {
        state.shopifyCustomerPreference = undefined;
      } else {
        state.shopifyCustomerPreference = {
          name: "Do not add it anywhere",
          value: "doNotAdd",
        };
      }

      state.isContactsSyncRulesSaved = false;
    },

    onChangeContactDropdownFields(state, { payload }) {
      if (payload.selectingFor === "fortnoxFilterDropdown") {
        state.fortnoxFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactsSyncRulesSaved = false;
      } else if (payload.selectingFor === "shopifyFilterDropdown") {
        state.shopifyFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactsSyncRulesSaved = false;
      } else if (payload.selectingFor === "shopifyCustomerNumber") {
        state.shopifyCustomerPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactsSyncRulesSaved = false;
      } else if (payload.selectingFor === "paymentTerms") {
        state.paymentTermPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "currency") {
        state.currencyPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "priceList") {
        state.priceListPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "deliveryWays") {
        state.deliveryWaysPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "deliveryTerms") {
        state.deliveryTermsPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "vatTypes") {
        state.vatTypesPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "languages") {
        state.languagesPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      }
    },

    setIsContactSyncRulesAndDefaultMappings(state, { payload }) {
      if (payload.type === "syncRules") {
        state.isContactsSyncRulesSaved = true;
      } else if (payload.type === "defaultMappings") {
        state.isContactDefaultMappingsSaved = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFortnoxDefaultMappings.pending, (state, action) => {
      if (!state.fortnoxDefaultMappings.loading) {
        state.fortnoxDefaultMappings.loading = true;
      }
    });
    builder.addCase(getFortnoxDefaultMappings.fulfilled, (state, action) => {
      if (state.fortnoxDefaultMappings.loading) {
        state.fortnoxDefaultMappings.loading = false;
        state.fortnoxDefaultMappings.fieldsData = action.payload.formattedData;
        state.fortnoxDefaultMappings.error = "";
      }
    });
    builder.addCase(getFortnoxDefaultMappings.rejected, (state, action) => {
      if (state.fortnoxDefaultMappings.loading) {
        state.fortnoxDefaultMappings.loading = false;
        state.fortnoxDefaultMappings.fieldsData = {};
        state.fortnoxDefaultMappings.error = action.error.message ?? "";
      }
    });

    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      const { preference } = action.payload;

      if (preference !== undefined && Object.keys(preference).length > 0) {
        const { customer } = preference;

        if (customer && Object.keys(customer).length > 0) {
          if (customer?.fortnoxCustomerNumber) {
            state.shopifyCustomerNumberPreference = {
              value: customer.fortnoxCustomerNumber.direction,
            };

            if (customer?.fortnoxCustomerNumber?.mapping) {
              state.shopifyCustomerPreference = customer?.fortnoxCustomerNumber
                ?.mapping?.shProperty?.value
                ? customer.fortnoxCustomerNumber.mapping.shProperty
                : {
                    name: "Fortnox customer number",
                    value: "fortnoxcustomernumber",
                  };
            }
          }

          if (customer?.filterOptions) {
            state.shopifyFilterPreference = customer?.filterOptions
              ?.filterSHProperty?.value
              ? customer.filterOptions.filterSHProperty
              : undefined;

            state.fortnoxFilterPreference = customer?.filterOptions
              ?.filterFxProperty?.value
              ? customer.filterOptions.filterFxProperty
              : undefined;
          }

          if (customer?.fortnoxFields) {
            const {
              currencies,
              paymentTerms,
              priceList,
              deliveryWays,
              deliveryTerms,
              vatTypes,
              languages,
            } = customer.fortnoxFields;

            state.paymentTermPreference = paymentTerms?.value
              ? paymentTerms
              : undefined;
            state.currencyPreference = currencies?.value
              ? currencies
              : undefined;
            state.priceListPreference = priceList?.value
              ? priceList
              : undefined;
            state.deliveryWaysPreference = deliveryWays?.value
              ? deliveryWays
              : undefined;
            state.deliveryTermsPreference = deliveryTerms?.value
              ? deliveryTerms
              : undefined;
            state.vatTypesPreference = vatTypes?.value ? vatTypes : undefined;
            state.languagesPreference = languages?.value
              ? languages
              : undefined;
          }
        }
      }

      if (
        state.shopifyFilterPreference &&
        state.fortnoxFilterPreference &&
        state.shopifyCustomerPreference &&
        state.shopifyCustomerNumberPreference
      ) {
        state.isContactsSyncRulesSaved = true;
      }

      if (
        state.paymentTermPreference &&
        state.currencyPreference &&
        state.priceListPreference &&
        state.deliveryWaysPreference &&
        state.deliveryTermsPreference &&
        state.vatTypesPreference &&
        state.languagesPreference
      ) {
        state.isContactDefaultMappingsSaved = true;
      }
    });
  },
});

export const {
  onChangeShopifyCustomerNumber,
  onChangeContactDropdownFields,
  setIsContactSyncRulesAndDefaultMappings,
} = contactSlice.actions;

export default contactSlice.reducer;
