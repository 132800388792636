import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button } from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { BsFillInfoCircleFill } from "react-icons/bs";

import FieldsDropdown from "../FieldsDropdown";

import { setIsContactSyncRulesAndDefaultMappings } from "../../store/slices/contactSlice";
import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../../store/slices/preferenceSlice";

import { getUrl } from "../../helpers/url";

import { useCmsHook } from "../../helpers/cmsHook";

const DefaultMappings = () => {
  const { cmsInstance } = useCmsHook();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const {
    paymentTermPreference,
    currencyPreference,
    priceListPreference,
    deliveryWaysPreference,
    deliveryTermsPreference,
    vatTypesPreference,
    languagesPreference,
    fortnoxDefaultMappings: {
      loading: fortnoxLoading,
      fieldsData: {
        paymentTerms,
        currencies,
        priceList,
        deliveryWays,
        deliveryTerms,
        vatTypes,
        languages,
      },
    },
  } = useSelector((state) => state.contact);

  return (
    <div>
      {fortnoxLoading || !cmsInstance ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center my-3"
          style={{ height: "40vh" }}
        >
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="md"
          ></Spinner>
        </div>
      ) : (
        <div className="px-3">
          <div className="sync-settings-card py-5">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.contact.defaultMappings"
              )}
            <div>
              <Col
                md={9}
                lg={7}
                className="d-flex flex-row justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  Payment Terms{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip style={{ fontSize: "12px" }}>
                        Payment terms define the amount of time the customer has
                        to pay the invoice. Here, a default value ensures
                        consistent, streamlined invoicing and cash flow.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {paymentTerms && paymentTerms.length > 0 && (
                  <FieldsDropdown
                    fieldsData={paymentTerms}
                    dropdownFor="paymentTerms"
                    selectedPreference={paymentTermPreference}
                    setWidth={true}
                  />
                )}
              </Col>
              <Col
                md={8}
                lg={7}
                className="d-flex justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  Currency{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Default currency is crucial to avoid discrepancies and
                        confusion in transaction values, especially for
                        businesses dealing with international customers.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {currencies && currencies.length > 0 && (
                  <FieldsDropdown
                    fieldsData={currencies}
                    dropdownFor="currency"
                    selectedPreference={currencyPreference}
                    setWidth={true}
                  />
                )}
              </Col>
              <Col
                md={8}
                lg={7}
                className="d-flex justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  Price List{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        The default price list ensures that the correct pricing
                        structure is consistently applied to all new customers,
                        maintaining pricing coherence and integrity.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {priceList && priceList.length > 0 && (
                  <FieldsDropdown
                    fieldsData={priceList}
                    dropdownFor="priceList"
                    selectedPreference={priceListPreference}
                    setWidth={true}
                  />
                )}
              </Col>
              <Col
                md={8}
                lg={7}
                className="d-flex justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  Delivery Ways{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        The default method by which goods or services will be
                        delivered to the customer. This ensures that there is a
                        standard way of delivering orders.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {deliveryWays && deliveryWays.length > 0 && (
                  <FieldsDropdown
                    fieldsData={deliveryWays}
                    dropdownFor="deliveryWays"
                    selectedPreference={deliveryWaysPreference}
                    setWidth={true}
                  />
                )}
              </Col>
              <Col
                md={8}
                lg={7}
                className="d-flex justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  Delivery Terms{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Delivery terms clarify the responsibilities of the buyer
                        and seller with regards to the delivery of goods, thus
                        preventing any potential disputes and misunderstandings.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {deliveryTerms && deliveryTerms.length > 0 && (
                  <FieldsDropdown
                    fieldsData={deliveryTerms}
                    dropdownFor="deliveryTerms"
                    selectedPreference={deliveryTermsPreference}
                    setWidth={true}
                  />
                )}
              </Col>
              <Col
                md={8}
                lg={7}
                className="d-flex justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  VAT Types{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Assigning a default VAT type is essential for accurate
                        tax calculations to ensure compliance with local tax
                        regulations and avoid legal complications.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {vatTypes && vatTypes.length > 0 && (
                  <FieldsDropdown
                    fieldsData={vatTypes}
                    dropdownFor="vatTypes"
                    selectedPreference={vatTypesPreference}
                    setWidth={true}
                  />
                )}
              </Col>
              <Col
                md={8}
                lg={7}
                className="d-flex justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  Languages{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Setting a default language ensures that all
                        communication and documentation are clear and
                        understandable, facilitating smoother interactions
                        between you and your customers.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {languages && languages.length > 0 && (
                  <FieldsDropdown
                    fieldsData={languages}
                    dropdownFor="languages"
                    selectedPreference={languagesPreference}
                    setWidth={true}
                  />
                )}
              </Col>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end mt-4 mb-2">
            {!paymentTermPreference ||
            !currencyPreference ||
            !priceListPreference ||
            !deliveryWaysPreference ||
            !deliveryTermsPreference ||
            !vatTypesPreference ||
            !languagesPreference ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>
                    {showTooltipText(
                      paymentTermPreference,
                      currencyPreference,
                      priceListPreference,
                      deliveryWaysPreference,
                      deliveryTermsPreference,
                      vatTypesPreference,
                      languagesPreference
                    )}
                  </Tooltip>
                }
              >
                <div>
                  <Button
                    style={{ cursor: "not-allowed" }}
                    className="sync-settings-button"
                    disabled
                  >
                    Save changes
                  </Button>
                </div>
              </OverlayTrigger>
            ) : (
              <Button
                className="sync-settings-button"
                loading={isLoading}
                primary
                onClick={() =>
                  onSaveDefaultMappings({
                    setIsLoading,
                    searchParams,
                    paymentTermPreference,
                    currencyPreference,
                    priceListPreference,
                    deliveryWaysPreference,
                    deliveryTermsPreference,
                    vatTypesPreference,
                    languagesPreference,
                    dispatch,
                    cmsInstance,
                  })
                }
                disabled={isLoading}
              >
                Save changes
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DefaultMappings;

const showTooltipText = (
  paymentTermsPreference,
  currencyPreference,
  priceListPreference,
  deliveryWaysPreference,
  deliveryTermsPreference,
  vatTypesPreference,
  languagesPreference
) => {
  if (!paymentTermsPreference) {
    return "Please select payment terms code.";
  } else if (!currencyPreference) {
    return "Please select currency code.";
  } else if (!priceListPreference) {
    return "Please select Price list.";
  } else if (!deliveryWaysPreference) {
    return "Please select Delivery ways.";
  } else if (!deliveryTermsPreference) {
    return "Please select Delivery Terms.";
  } else if (!vatTypesPreference) {
    return "Please select the VAT Types";
  } else if (!languagesPreference) {
    return "Please select the Language.";
  }
};

export const onSaveDefaultMappings = async ({
  setIsLoading,
  searchParams,
  paymentTermPreference,
  currencyPreference,
  priceListPreference,
  deliveryWaysPreference,
  deliveryTermsPreference,
  vatTypesPreference,
  languagesPreference,
  dispatch,
  cmsInstance,
}) => {
  try {
    setIsLoading(true);
    const shop = searchParams.get("shop");
    await axios.post(getUrl("REACT_APP_SAVE_PREFERENCES"), {
      shop,

      preferences: {
        fortnoxFields: {
          paymentTerms: paymentTermPreference,
          priceList: priceListPreference,
          deliveryWays: deliveryWaysPreference,
          deliveryTerms: deliveryTermsPreference,
          vatTypes: vatTypesPreference,
          languages: languagesPreference,
          currencies: currencyPreference,
        },
      },
      preferenceType: "customer",
    });
    dispatch(
      setIsContactSyncRulesAndDefaultMappings({ type: "defaultMappings" })
    );

    dispatch(
      setShowSuccessModal({
        message:
          cmsInstance &&
          cmsInstance.sendData().cmsContent.notifications.syncSettings
            .successContent.contactDefaultMappings.message,
      })
    );
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};
