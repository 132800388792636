import React, { useState, useEffect } from "react";
import {
  Navbar,
  //Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Button } from "@shopify/polaris";
// import { Redirect } from "@shopify/app-bridge/actions";
// import createApp from "@shopify/app-bridge";

import { useSearchParams, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import ConnectFortnox from "./ConnectFortnox";
import SyncSettings from "./SyncSettings";
import ConfirmationPage from "./ConfirmationPage";
import Subscription from "./Subscription";

import axios from "axios";
import { toast } from "react-toastify";

import { getUrl } from "../helpers/url";
import { onChangeSettingsActiveTab } from "../store/slices/preferenceSlice";

// cms helpers
import { CmsConstructor } from "../helpers/cmsConstructor";
import { useCmsHook } from "../helpers/cmsHook";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

const WINDOW = Object.freeze({
  AUTHENTICATION: "authentication",
  SYNC_SETTINGS: "sync settings",
  CONFIRMATION: "confirmation",
  SUBSCRIPTION: "subscription",
  ENVIRONMENT: "environment",
  EXTENSION_CHECK: "check extension",
});

const windowObj = {
  [WINDOW.AUTHENTICATION]: <ConnectFortnox />,
  [WINDOW.SYNC_SETTINGS]: <SyncSettings />,
  [WINDOW.CONFIRMATION]: <ConfirmationPage />,
  [WINDOW.SUBSCRIPTION]: <Subscription />,
};

const Setup = () => {
  const { setCmsInstance, cmsInstance, isEdit } = useCmsHook();

  const [currentWindow, setCurrentWindow] = useState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const session = searchParams.get("session");

  //preference states
  const {
    settingsActiveTab,
    isFortnoxConnected,
    data: {
      status: { creds },
    },
  } = useSelector((state) => state.preference);

  // mapping states
  const {
    isMappingsSaved,
    deletedShopifyProperties,
    shopify: { loading: shopifyLoading },
    fortnox: { loading: fortnoxLoading },
  } = useSelector((state) => state.mapping);

  // contact states
  const { isContactsSyncRulesSaved, isContactDefaultMappingsSaved } =
    useSelector((state) => state.contact);

  // invoice states
  const { isInvoiceSyncRulesSaved } = useSelector((state) => state.invoice);

  // product states
  const { isProductSyncRulesSaved } = useSelector((state) => state.product);

  const onNextNavigation = () => {
    const shop = searchParams.get("shop");
    const session = searchParams.get("session");
    const host = searchParams.get("host");
    if (currentWindow === WINDOW.AUTHENTICATION) {
      if (session) {
        navigate(
          `${window.location.pathname}?shop=${shop}&paymentStatus=none&session=true&host=${host}`
        );
      } else {
        navigate(`${window.location.pathname}?shop=${shop}&paymentStatus=none`);
      }
      setCurrentWindow(WINDOW.SUBSCRIPTION);
    } else if (currentWindow === WINDOW.SUBSCRIPTION) {
      if (session) {
        navigate(
          `${window.location.pathname}?shop=${shop}&syncSettings=none&session=true&host=${host}`
        );
      } else {
        navigate(`${window.location.pathname}?shop=${shop}&syncSettings=none`);
      }
      setCurrentWindow(WINDOW.SYNC_SETTINGS);
      dispatch(
        onChangeSettingsActiveTab({
          selectedTab: "contact",
          isContactsSyncRulesSaved,
          isContactDefaultMappingsSaved,
          isCustomerMappingsSaved: isMappingsSaved.customer,
          isInvoiceSyncRulesSaved,
          isInvoiceMappingsSaved: isMappingsSaved.invoice,
          isProductSyncRulesSaved,
          isProductMappingsSaved: isMappingsSaved.productFieldMappings,
          shopifyLoading,
          fortnoxLoading,
        })
      );
    } else if (
      currentWindow === WINDOW.SYNC_SETTINGS &&
      settingsActiveTab === "contact"
    ) {
      dispatch(
        onChangeSettingsActiveTab({
          selectedTab: "product",
          isContactsSyncRulesSaved,
          isContactDefaultMappingsSaved,
          isCustomerMappingsSaved: isMappingsSaved.customer,
          isInvoiceSyncRulesSaved,
          isInvoiceMappingsSaved: isMappingsSaved.invoice,
          isProductSyncRulesSaved,
          isProductMappingsSaved: isMappingsSaved.productFieldMappings,
          shopifyLoading,
          fortnoxLoading,
        })
      );
    } else if (
      currentWindow === WINDOW.SYNC_SETTINGS &&
      settingsActiveTab === "product"
    ) {
      dispatch(
        onChangeSettingsActiveTab({
          selectedTab: "invoice",
          isContactsSyncRulesSaved,
          isContactDefaultMappingsSaved,
          isCustomerMappingsSaved: isMappingsSaved.customer,
          isInvoiceSyncRulesSaved,
          isInvoiceMappingsSaved: isMappingsSaved.invoice,
          isProductSyncRulesSaved,
          isProductMappingsSaved: isMappingsSaved.productFieldMappings,
          shopifyLoading,
          fortnoxLoading,
        })
      );
    } else if (
      currentWindow === WINDOW.SYNC_SETTINGS &&
      settingsActiveTab === "invoice"
    ) {
      if (session) {
        navigate(
          `${window.location.pathname}?shop=${shop}&confirmation=true&session=true&host=${host}`
        );
      } else {
        navigate(`${window.location.pathname}?shop=${shop}&confirmation=true`);
      }

      setCurrentWindow(WINDOW.CONFIRMATION);
    }
  };

  const onBackNavigation = () => {
    const shop = searchParams.get("shop");
    const session = searchParams.get("session");
    const host = searchParams.get("host");
    if (currentWindow === WINDOW.CONFIRMATION) {
      if (session) {
        navigate(
          `${window.location.pathname}?shop=${shop}&syncSettings=none&session=true&host=${host}`
        );
      } else {
        navigate(`${window.location.pathname}?shop=${shop}&syncSettings=none`);
      }

      setCurrentWindow(WINDOW.SYNC_SETTINGS);
      dispatch(
        onChangeSettingsActiveTab({
          selectedTab: "invoice",
          isContactsSyncRulesSaved,
          isContactDefaultMappingsSaved,
          isCustomerMappingsSaved: isMappingsSaved.customer,
          isInvoiceSyncRulesSaved,
          isInvoiceMappingsSaved: isMappingsSaved.invoice,
          isProductSyncRulesSaved,
          isProductMappingsSaved: isMappingsSaved.productFieldMappings,
          shopifyLoading,
          fortnoxLoading,
        })
      );
    } else if (
      currentWindow === WINDOW.SYNC_SETTINGS &&
      settingsActiveTab === "invoice"
    ) {
      dispatch(
        onChangeSettingsActiveTab({
          selectedTab: "product",
          isContactsSyncRulesSaved,
          isContactDefaultMappingsSaved,
          isCustomerMappingsSaved: isMappingsSaved.customer,
          isInvoiceSyncRulesSaved,
          isInvoiceMappingsSaved: isMappingsSaved.invoice,
          isProductSyncRulesSaved,
          isProductMappingsSaved: isMappingsSaved.productFieldMappings,
          shopifyLoading,
          fortnoxLoading,
        })
      );
    } else if (
      currentWindow === WINDOW.SYNC_SETTINGS &&
      settingsActiveTab === "product"
    ) {
      dispatch(
        onChangeSettingsActiveTab({
          selectedTab: "contact",
          isContactsSyncRulesSaved,
          isContactDefaultMappingsSaved,
          isCustomerMappingsSaved: isMappingsSaved.customer,
          isInvoiceSyncRulesSaved,
          isInvoiceMappingsSaved: isMappingsSaved.invoice,
          isProductSyncRulesSaved,
          isProductMappingsSaved: isMappingsSaved.productFieldMappings,
          shopifyLoading,
          fortnoxLoading,
        })
      );
    } else if (
      currentWindow === WINDOW.SYNC_SETTINGS &&
      settingsActiveTab === "contact"
    ) {
      if (session) {
        navigate(
          `${window.location.pathname}?shop=${shop}&paymentStatus=none&session=true&host=${host}`
        );
      } else {
        navigate(`${window.location.pathname}?shop=${shop}&paymentStatus=none`);
      }
      setCurrentWindow(WINDOW.SUBSCRIPTION);
    } else if (currentWindow === WINDOW.SUBSCRIPTION) {
      if (session) {
        navigate(
          `${window.location.pathname}?shop=${shop}&session=true&host=${host}`
        );
      } else {
        navigate(`${window.location.pathname}?shop=${shop}`);
      }
      setCurrentWindow(WINDOW.AUTHENTICATION);
    }
  };

  const goBackToShopify = () => {
    const shopId = searchParams.get("shop");
    window.open(shopId && `https://${shopId}/admin`, "_self");
  };

  const redirectToEmbeddedApp = async () => {
    const { data: res } = await axios.post(getUrl("REACT_APP_REDIRECT"), {
      host: searchParams.get("host"),
      path: "/",
    });
    window.open(res, "_self");
  };

  const install = async () => {
    try {
      const code = searchParams.get("code");
      const host = searchParams.get("host");
      const shop = searchParams.get("shop");
      const timestamp = searchParams.get("timestamp");
      const hmac = searchParams.get("hmac");
      const syncSettings = searchParams.get("syncSettings");
      const paymentStatus = searchParams.get("paymentStatus");
      const confirmation = searchParams.get("confirmation");

      if (code && hmac && timestamp && shop && host) {
        setLoading(true);
        const { data: res } = await axios.post(getUrl("REACT_APP_INSTALL"), {
          code,
          hmac,
          timestamp,
          shop,
          host,
        });

        if (res && res.shop !== "") {
          redirectToEmbeddedApp();
        }
      } else if (creds && creds?.shopify) {
        setCurrentWindow(WINDOW.AUTHENTICATION);
        navigate(`../setup?shop=${shop}`);
      } else if (shop && paymentStatus) {
        setCurrentWindow(WINDOW.SUBSCRIPTION);
      } else if (shop && syncSettings) {
        setCurrentWindow(WINDOW.SYNC_SETTINGS);
      } else if (shop && confirmation) {
        setCurrentWindow(WINDOW.CONFIRMATION);
      } else if (shop) {
        setCurrentWindow(WINDOW.AUTHENTICATION);
      }
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      toast.success(errorMessage, { className: "react-toast-error" });
    } finally {
      if (searchParams.get("session")) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  };

  useEffect(() => {
    install();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cmsInstance = async () => {
      const cmsObj = await CmsConstructor();
      setCmsInstance(cmsObj);
    };
    cmsInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <div>
          <Navbar fixed="top" className="px-4 navbar-container py-3">
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <div>
                {currentWindow !== WINDOW.AUTHENTICATION ||
                currentWindow === WINDOW.CONFIRMATION ? (
                  <Button
                    className="back-button"
                    onClick={() => onBackNavigation()}
                  >
                    Back
                  </Button>
                ) : (
                  <p className="empty-space"></p>
                )}
              </div>
              <div className="text-center">
                {cmsInstance &&
                  cmsInstance.showRichText("cmsContent.header.text")}
              </div>
              <div>
                {currentWindow !== WINDOW.CONFIRMATION ? (
                  <>
                    {(currentWindow === WINDOW.AUTHENTICATION &&
                      !isFortnoxConnected) ||
                    (currentWindow === WINDOW.SYNC_SETTINGS &&
                      ((settingsActiveTab === "contact" &&
                        (!isContactsSyncRulesSaved ||
                          !isContactDefaultMappingsSaved ||
                          !isMappingsSaved.customer ||
                          deletedShopifyProperties.customer.length > 0)) ||
                        (settingsActiveTab === "product" &&
                          (!isProductSyncRulesSaved ||
                            !isMappingsSaved.productFieldMappings ||
                            deletedShopifyProperties.productFieldMappings
                              .length > 0)) ||
                        (settingsActiveTab === "invoice" &&
                          (!isMappingsSaved.invoice ||
                            !isInvoiceSyncRulesSaved ||
                            deletedShopifyProperties.invoice.length > 0)))) ? (
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip style={{ fontSize: "12px" }}>
                            {showTooltipText(
                              currentWindow,
                              isMappingsSaved,
                              isFortnoxConnected,
                              isContactsSyncRulesSaved,
                              isContactDefaultMappingsSaved,
                              deletedShopifyProperties,
                              isInvoiceSyncRulesSaved,
                              isProductSyncRulesSaved,
                              settingsActiveTab
                            )}
                          </Tooltip>
                        }
                      >
                        <div>
                          <Button
                            className="next-button"
                            style={{ opacity: "0.8", cursor: "not-allowed" }}
                          >
                            Next
                          </Button>
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <Button
                        className="next-button"
                        onClick={() => onNextNavigation()}
                        primary
                      >
                        Next
                      </Button>
                    )}
                  </>
                ) : (
                  <div className={session && "d-none"}>
                    <Button onClick={goBackToShopify} primary>
                      Finish
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Navbar>
          <div
            style={{
              marginTop:
                isEdit && window.location.pathname === "/setup"
                  ? "230px"
                  : "80px",
            }}
          >
            <CmsEditAndSave />

            <div>{windowObj[currentWindow]}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Setup;

const showTooltipText = (
  currentWindow,
  isMappingsSaved,
  isFortnoxConnected,
  isContactsSyncRulesSaved,
  isContactDefaultMappingsSaved,
  deletedShopifyProperties,
  isInvoiceSyncRulesSaved,
  isProductSyncRulesSaved,
  settingsActiveTab
) => {
  if (currentWindow === WINDOW.AUTHENTICATION && !isFortnoxConnected) {
    return "Please connect your Fortnox account before proceeding further.";
  } else if (currentWindow === WINDOW.SYNC_SETTINGS) {
    if (settingsActiveTab === "contact") {
      if (!isContactsSyncRulesSaved) {
        return "please save the contact sync rules before proceeding further.";
      } else if (!isContactDefaultMappingsSaved) {
        return "Please save the contact default mappings before proceeding further.";
      } else if (!isMappingsSaved.customer) {
        return "Please the save the customer mappings before proceeding further.";
      } else if (deletedShopifyProperties.customer.length > 0) {
        return "Please remap the contact Mappings for deleted Shopify fields before proceeding further";
      }
    } else if (settingsActiveTab === "invoice") {
      if (!isInvoiceSyncRulesSaved) {
        return "please save the invoice sync rules before proceeding further.";
      } else if (!isMappingsSaved.invoice) {
        return "Please the save the invoice mappings before proceeding further.";
      } else if (deletedShopifyProperties.invoice.length > 0) {
        return "Please remap the invoice Mappings for deleted Shopify fields before proceeding further";
      }
    } else if (settingsActiveTab === "product") {
      if (!isProductSyncRulesSaved) {
        return "please save the product sync rules before proceeding further.";
      } else if (!isMappingsSaved.productFieldMappings) {
        return "Please the save the product mappings before proceeding further.";
      } else if (deletedShopifyProperties.productFieldMappings.length > 0) {
        return "Please remap the product field Mappings for deleted Shopify fields before proceeding further";
      }
    }
  }
};
