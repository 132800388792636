import React, { useEffect } from "react";
import { Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import PostpaidBillingCards from "../components/subscription/PostpaidBillingCards";

import { fetchStatus } from "../store/slices/preferenceSlice";

// CMS Helpers
import { useCmsHook } from "../helpers/cmsHook";
import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

function Subscription() {
  const { cmsInstance, setCmsInstance } = useCmsHook();

  const { isFortnoxConnected } = useSelector((state) => state.preference);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.pathname === "/subscription") {
      const fetchCmsInstance = async () => {
        const cmsObj = await CmsConstructor();
        setCmsInstance(cmsObj);
      };
      fetchCmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const shop = searchParams.get("shop");

    if (shop) {
      dispatch(fetchStatus({ shop }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-container">
      {!cmsInstance ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <>
          {window.location.pathname === "/subscription" && <CmsEditAndSave />}
          {!isFortnoxConnected && (
            <div className="d-flex flex-row align-items-center justify-content-center pt-4">
              <Alert variant="warning" className="w-50 mb-0 alert-message">
                Please connect your Fortnox account.
              </Alert>
            </div>
          )}
          <Container
            className="subscription-container"
            style={{
              minHeight: `${
                window.location.pathname === "/setup" ? "90vh" : "100vh"
              }`,
            }}
          >
            <Row className="d-flex flex-row justify-content-center my-5">
              <Col xs={12} lg={9} className="px-0">
                <div className="text-center" style={{ marginBottom: "50px" }}>
                  {cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.subscription.heading",
                      "my-3"
                    )}
                  {cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.subscription.description",
                      "mt-2"
                    )}
                </div>

                <div>
                  <PostpaidBillingCards cmsInstance={cmsInstance} />
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
}

export default Subscription;
