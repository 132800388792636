import React, { useEffect } from "react";
import { Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { onChangeProductActiveTab } from "../store/slices/preferenceSlice";
import { clearDefaultValues } from "../store/slices/mappingSlice";

import SyncRules from "../components/products/SyncRules";
import FieldMappings from "../components/products/FieldMappings";

const ProductSync = () => {
  const productTabKeys = Object.freeze({
    syncRules: "syncRules",
    fieldMappings: "fieldMappings",
  });

  const dispatch = useDispatch();

  // preference states
  const { productActiveTab } = useSelector((state) => state.preference);

  // contact states
  const { isProductSyncRulesSaved, preferenceForProducts } = useSelector(
    (state) => state.product
  );

  // mapping states
  const {
    isMappingsSaved: { productFieldMappings: isProductMappingsSaved },
    shopify: { loading: shopifyLoading },
    fortnox: { loading: fortnoxLoading },
  } = useSelector((state) => state.mapping);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Tabs
      activeKey={productActiveTab}
      onSelect={(selectedKey) => {
        window.scrollTo(0, 0);
        dispatch(clearDefaultValues());
        dispatch(
          onChangeProductActiveTab({
            selectedTab: selectedKey,
            isProductSyncRulesSaved,
            isProductMappingsSaved,
            shopifyLoading,
            fortnoxLoading,
          })
        );
      }}
      className="nested-sync-tabs mt-4"
    >
      <Tab eventKey={productTabKeys.syncRules} title="Product sync rules">
        <SyncRules />
      </Tab>
      {preferenceForProducts === "findCreateProduct" ? (
        <Tab
          eventKey={productTabKeys.fieldMappings}
          title="Product field mappings"
          disabled={preferenceForProducts !== "findCreateProduct"}
        >
          <FieldMappings />
        </Tab>
      ) : (
        <Tab
          title={
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip style={{ fontSize: "12px" }}>
                  Product field mappings are applicable only if Create a new
                  product is selected under Product not found.
                </Tooltip>
              }
            >
              <span>Product field mappings</span>
            </OverlayTrigger>
          }
        ></Tab>
      )}
    </Tabs>
  );
};

export default ProductSync;
