import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { getUrl } from "../../helpers/url";

const initialState = {
  settingsActiveTab: "contact",
  contactsActiveTab: "syncRules",
  invoiceActiveTab: "syncRules",
  productActiveTab: "syncRules",
  isFortnoxConnected: false,
  saveModalPreference: false,
  data: {
    loading: false,
    error: "",
    status: {},
  },
  selectedTabValues: {},
  errorMessage: "",
  successMessage: "",
  showSuccessToast: false,
  showErrorToast: false,
};

export const fetchStatus = createAsyncThunk(
  "preferences/fetchStatus",
  async ({ shop }, { dispatch }) => {
    try {
      const { data: response } = await axios.get(
        getUrl("REACT_APP_GET_STATUS"),
        {
          params: {
            shop,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
      throw error;
    }
  }
);

export const preferenceSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    onChangeSettingsActiveTab(state, { payload }) {
      if (
        state.isFortnoxConnected &&
        !payload.shopifyLoading &&
        !payload.fortnoxLoading &&
        state.settingsActiveTab === "contact" &&
        (payload.selectedTab === "invoice" ||
          payload.selectedTab === "product") &&
        (!payload.isContactsSyncRulesSaved ||
          !payload.isContactDefaultMappingsSaved ||
          !payload.isCustomerMappingsSaved)
      ) {
        state.selectedTabValues = {
          tab: payload.selectedTab,
          subTab: "syncRules",
        };
        state.saveModalPreference = true;
      } else if (
        state.isFortnoxConnected &&
        !payload.shopifyLoading &&
        !payload.fortnoxLoading &&
        state.settingsActiveTab === "invoice" &&
        (payload.selectedTab === "contact" ||
          payload.selectedTab === "product") &&
        (!payload.isInvoiceSyncRulesSaved || !payload.isInvoiceMappingsSaved)
      ) {
        state.selectedTabValues = {
          tab: payload.selectedTab,
          subTab: "syncRules",
        };
        state.saveModalPreference = true;
      } else if (
        state.isFortnoxConnected &&
        !payload.shopifyLoading &&
        !payload.fortnoxLoading &&
        state.settingsActiveTab === "product" &&
        (payload.selectedTab === "contact" ||
          payload.selectedTab === "invoice") &&
        (!payload.isProductMappingsSaved || !payload.isProductSyncRulesSaved)
      ) {
        state.selectedTabValues = {
          tab: payload.selectedTab,
          subTab: "syncRules",
        };
        state.saveModalPreference = true;
      } else {
        state.settingsActiveTab = payload.selectedTab;
      }
    },

    onChangeContactActiveTab(state, { payload }) {
      if (
        state.isFortnoxConnected &&
        !payload.shopifyLoading &&
        !payload.fortnoxLoading &&
        state.contactsActiveTab === "syncRules" &&
        !payload.isContactsSyncRulesSaved &&
        (payload.selectedTab === "defaultMappings" ||
          payload.selectedTab === "fieldMappings")
      ) {
        state.selectedTabValues = {
          tab: "contact",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        state.isFortnoxConnected &&
        !payload.shopifyLoading &&
        !payload.fortnoxLoading &&
        state.contactsActiveTab === "defaultMappings" &&
        !payload.isContactDefaultMappingsSaved &&
        (payload.selectedTab === "syncRules" ||
          payload.selectedTab === "fieldMappings")
      ) {
        state.selectedTabValues = {
          tab: "contact",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        state.isFortnoxConnected &&
        !payload.shopifyLoading &&
        !payload.fortnoxLoading &&
        state.contactsActiveTab === "fieldMappings" &&
        !payload.isCustomerMappingsSaved &&
        (payload.selectedTab === "syncRules" ||
          payload.selectedTab === "defaultMappings")
      ) {
        state.selectedTabValues = {
          tab: "contact",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else {
        state.contactsActiveTab = payload.selectedTab;
      }
    },

    onChangeInvoiceActiveTab(state, { payload }) {
      if (
        state.isFortnoxConnected &&
        !payload.shopifyLoading &&
        !payload.fortnoxLoading &&
        state.invoiceActiveTab === "syncRules" &&
        !payload.isInvoiceSyncRulesSaved &&
        payload.selectedTab === "fieldMappings"
      ) {
        state.selectedTabValues = {
          tab: "invoice",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        state.isFortnoxConnected &&
        !payload.shopifyLoading &&
        !payload.fortnoxLoading &&
        state.invoiceActiveTab === "fieldMappings" &&
        !payload.isInvoiceMappingsSaved &&
        payload.selectedTab === "syncRules"
      ) {
        state.selectedTabValues = {
          tab: "invoice",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else {
        state.invoiceActiveTab = payload.selectedTab;
      }
    },

    onChangeProductActiveTab(state, { payload }) {
      if (
        state.isFortnoxConnected &&
        !payload.shopifyLoading &&
        !payload.fortnoxLoading &&
        state.productActiveTab === "syncRules" &&
        !payload.isProductSyncRulesSaved &&
        payload.selectedTab === "fieldMappings"
      ) {
        state.selectedTabValues = {
          tab: "product",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        state.isFortnoxConnected &&
        !payload.shopifyLoading &&
        !payload.fortnoxLoading &&
        state.productActiveTab === "fieldMappings" &&
        !payload.isProductMappingsSaved &&
        payload.selectedTab === "syncRules"
      ) {
        state.selectedTabValues = {
          tab: "product",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else {
        state.productActiveTab = payload.selectedTab;
      }
    },

    onChangeFortnoxConnected(state, { payload }) {
      state.isFortnoxConnected = payload.isConnected;
    },

    onChangeSaveModalPreference(state, { payload }) {
      state.saveModalPreference = payload.show;
    },

    onChangeCheckExtensionInstalled(state, { payload }) {
      state.checkExtensionInstalled = payload.connected;
    },

    setShowExtensionModal(state, { payload }) {
      state.showExtensionModal = payload.show;
    },

    onHideExtensionModal(state) {
      state.showExtensionModal = false;
    },

    setShowSuccessModal(state, { payload }) {
      state.successMessage = payload.message;
      state.showSuccessToast = true;
    },

    onHideSuccessModal(state) {
      state.successMessage = "";
      state.showSuccessToast = false;
    },

    setShowErrorModal(state, { payload }) {
      state.errorMessage = payload.message;
      state.showErrorToast = true;
    },

    onHideErrorModal(state) {
      state.errorMessage = "";
      state.showErrorToast = false;
    },

    setDropdownPreferences(state, { payload }) {
      if (payload.dropdownFor === "environment") {
        state.environmentPreference = payload.selectedOption;
      } else if (payload.dropdownFor === "company") {
        state.companyPreference = payload.selectedOption;
      }
      state.isEnvPreferencesSaved = false;
    },

    setIsEnvPreferencesSaved(state, { payload }) {
      state.isEnvPreferencesSaved = true;
    },

    onChangeTabValues(state) {
      window.scroll(0, 0);
      state.settingsActiveTab = state.selectedTabValues.tab;
      if (state.selectedTabValues.tab === "contact") {
        state.contactsActiveTab = state.selectedTabValues.subTab;
      } else if (state.selectedTabValues.tab === "product") {
        state.productActiveTab = state.selectedTabValues.subTab;
      } else {
        state.invoiceActiveTab = state.selectedTabValues.subTab;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatus.pending, (state, action) => {
      if (!state.data.loading) {
        state.data.loading = true;
      }
    });
    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      if (state.data.loading) {
        state.data.loading = false;
        state.data.status = action.payload;
        state.data.error = "";

        const { creds } = action.payload;
        if (creds?.fortnox) state.isFortnoxConnected = true;
      }
    });
    builder.addCase(fetchStatus.rejected, (state, action) => {
      if (state.data.loading) {
        state.data.loading = false;
        state.data.status = {};
        state.data.error = action.error.message ?? "";
      }
    });
  },
});

export const {
  onChangeSettingsActiveTab,
  onChangeContactActiveTab,
  onChangeInvoiceActiveTab,
  onChangeFortnoxConnected,
  onChangeSaveModalPreference,
  onChangeTabValues,
  setDropdownPreferences,
  setIsEnvPreferencesSaved,
  onChangeCheckExtensionInstalled,
  setShowExtensionModal,
  onHideExtensionModal,
  onChangeProductActiveTab,
  setShowSuccessModal,
  onHideSuccessModal,
  setShowErrorModal,
  onHideErrorModal,
} = preferenceSlice.actions;

export default preferenceSlice.reducer;
