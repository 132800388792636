import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

const Redirect = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");
    const paymentStatus = searchParams.get("paymentStatus");
    const plan = searchParams.get("plan");
    const session = searchParams.get("session");
    const shop = searchParams.get("shop");
    const code = searchParams.get("code");
    const timestamp = searchParams.get("timestamp");
    const hmac = searchParams.get("hmac");
    const host = searchParams.get("host");

    if (session && host) {
      navigate(`../setup?shop=${shop}&session=true&host=${host}`);
    } else if (code && timestamp && hmac && host && shop) {
      navigate(
        `../setup?code=${code}&shop=${shop}&hmac=${hmac}&host=${host}&timestamp=${timestamp}`
      );
    } else {
      window.open(
        `https://${shop}/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_CLIENT}&scope=${process.env.REACT_APP_SHOPIFY_SCOPES}&redirect_uri=${window.location.origin}/redirect`,
        "_self"
      );
    }

    if (token) {
      console.log("received token");
    }
    if (paymentStatus) {
      window.opener.postMessage({ paymentStatus, plan }, "*");
      window.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default Redirect;
