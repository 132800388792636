import { createSlice } from "@reduxjs/toolkit";
import { fetchStatus } from "./preferenceSlice";

const initialState = {
  cardsData: [
    {
      id: "card1",
      name: "Free Trial",
      text: "Our free trial includes access to all the premium features.",
      price: "USD 0",
      points: [
        "15 trial credits with no limit",
        "Create invoices and customers from Salesforce to e-conomic",
        "Custom rules and mapping for creating customers and invoices in e-conomic",
        "Re-trigger failed sync from HubSpot activity log",
      ],
    },
    {
      id: "card2",
      name: "Premium",
      text: "Premium plan comes the features with a fixed cost of:",
      price: "USD 15/mo",
      points: [
        "Pay as you use @ 0.25 USD/sync",
        "Create invoices and customers from Salesforce to e-conomic",
        "Custom rules and mapping for creating customers and invoices in ",
        "Re-trigger failed sync from HubSpot activity log",
      ],
    },
  ],
  subscription: {
    loading: false,
    planData: {},
    error: "",
    currentRequestId: undefined,
  },
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    updatePlanData(state, { payload }) {
      state.subscription.planData = {
        ...state.subscription.planData,
        subscriptionId: payload.subscriptionId,
        plan: payload.plan,
        usageCount: payload.usageCount,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatus.fulfilled, (state, actions) => {
      const { subscription } = actions.payload;
      state.subscription.planData = subscription;
    });
  },
});

export const { updatePlanData } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
