import React, { useEffect } from "react";

import { Provider } from "@shopify/app-bridge-react";

import SyncSettings from "./SyncSettings";

import { CmsConstructor } from "../helpers/cmsConstructor";
import { useCmsHook } from "../helpers/cmsHook";
//import CmsEditAndSave from "../helpers/CmsEditAndSave";

const SettingsPage = () => {
  const { setCmsInstance } = useCmsHook();

  const config = {
    apiKey: process.env.REACT_APP_SHOPIFY_CLIENT,
    host: new URLSearchParams(window.location.search).get("host"),
  };

  useEffect(() => {
    if (window.location.pathname === "/settings") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor();
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider config={config}>
      <SyncSettings />
    </Provider>
  );
};

export default SettingsPage;
