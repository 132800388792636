import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { getUrl } from "../../helpers/url";

import { fetchStatus, setShowErrorModal } from "./preferenceSlice";

const initialState = {
  searchInput: "",
  searchResultsArray: [],
  selectShopifyPreference: undefined,
  currentlyEditing: undefined,
  isEditMapping: false,
  isMappingsSaved: {
    customer: true,
    invoice: true,
    products: true,
    productFieldMappings: true,
  },
  shopify: {
    loading: false,
    shopifyDropdownFields: {},
    error: "",
  },
  fortnox: {
    loading: false,
    fortnoxDropdownFields: {},
    error: "",
  },
  mappings: {
    loading: false,
    defaultMappings: {
      customer: [],
      products: [],
      invoice: [],
      productFieldMappings: [],
    },
    error: "",
  },
  deletedShopifyProperties: {
    customer: [],
    products: [],
    invoice: [],
    productFieldMappings: [],
  },
  deletedFortnoxProperties: {
    customer: [],
    products: [],
    invoice: [],
    productFieldMappings: [],
  },
  activeAccordionItem: {
    customer: "0",
    deal: "0",
  },
  activeAccordionTitle: {
    customer: "General",
    deal: "Delivery address",
  },
  itemOffset: 0,
  slicedMappings: [],
  pageCount: 0,
  selectedIndex: 0,
};

export const fetchShopifyFields = createAsyncThunk(
  "mapping/fetchShopifyFields",
  async ({ shop }, { dispatch }) => {
    try {
      const { data: response } = await axios.get(
        getUrl("REACT_APP_GET_SHOPIFY_FIELDS"),
        {
          params: {
            shop,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
      throw error;
    }
  }
);

export const fetchFortnoxFields = createAsyncThunk(
  "mapping/fetchFortnoxFields",
  async ({ shop }, { dispatch }) => {
    try {
      const { data: response } = await axios.get(
        getUrl("REACT_APP_GET_FORTNOX_FIELDS"),
        {
          params: {
            shop,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
      throw error;
    }
  }
);

export const mappingSlice = createSlice({
  name: "mapping",
  initialState,
  reducers: {
    updatePageCount(state, { payload }) {
      state.pageCount = payload.count;
    },

    updateItemOffset(state, { payload }) {
      state.itemOffset = payload.itemOffset;
    },

    updateSelectedIndex(state, { payload }) {
      state.selectedIndex = payload.selectedIndex;
    },

    updateSlicedMappings(state, { payload }) {
      state.slicedMappings = payload.mappings;
    },

    checkUnmappedFields(state, { payload }) {
      const newSet = new Set(
        state.mappings.defaultMappings[payload.mappingsFor].map(
          (mappedItem) => mappedItem.FX_FieldName_CRF
        )
      );

      state.fortnox.fortnoxDropdownFields[payload.mappingsFor].forEach(
        (fxProperty) => {
          if (!newSet.has(fxProperty.CRF)) {
            if (payload.mappingsFor !== "products") {
              state.mappings.defaultMappings[payload.mappingsFor].push({
                FX_FieldName_CRF: fxProperty.CRF,
                FX_FieldName_HRF: fxProperty.HRF,
                FX_FieldType: fxProperty.type,
                FX_Field_Description: fxProperty.description,
                FX_Field_Required: fxProperty.required && fxProperty.required,
                SH_FieldName_CRF: undefined,
                SH_FieldName_HRF: undefined,
                SH_FieldType: undefined,
                SH_Field_Description: undefined,
                id: `${fxProperty.CRF}-${payload.mappingsFor}`,
              });
            } else {
              state.mappings.defaultMappings[payload.mappingsFor].push({
                FX_FieldName_CRF: fxProperty.CRF,
                FX_FieldName_HRF: fxProperty.HRF,
                FX_FieldType: fxProperty.type,
                FX_Field_Description: fxProperty.description,
                FX_Field_Required: fxProperty.required && fxProperty.required,
                SH_Fields: [],
                id: `${fxProperty.CRF}-${payload.mappingsFor}`,
              });
            }
          }
        }
      );
    },

    storeActiveAccordion(state, { payload }) {
      state.activeAccordionItem[payload.mappingsFor] = payload.activeAccordion;
      state.isEditMapping = false;
      state.currentlyEditing = "";
      state.searchInput = "";
      state.searchResultsArray = [];
    },

    storeActiveAccordionTitle(state, { payload }) {
      state.activeAccordionTitle[payload.mappingsFor] = payload.accordionTitle;
    },

    setShopifyPreference(state, { payload }) {
      state.selectShopifyPreference = payload.selectedOption;
    },

    onEditMappings(state, { payload }) {
      state.currentlyEditing = payload.id;
      state.isEditMapping = true;
    },

    onSearchMappings(state, { payload }) {
      const { mappingsFor, enteredInput } = payload;

      state.searchInput = enteredInput;
      state.searchResultsArray = [];

      if (state.searchInput !== "") {
        state.mappings.defaultMappings[mappingsFor].forEach((mappedItem) => {
          if (
            mappedItem.FX_FieldName_HRF.toLowerCase().includes(
              enteredInput.toLowerCase()
            ) ||
            (mappedItem.SH_FieldName_HRF !== undefined &&
              mappedItem.SH_FieldName_HRF.toLowerCase().includes(
                enteredInput.toLowerCase()
              ))
          ) {
            state.searchResultsArray.push(mappedItem.id);
          }
        });
      }
    },

    onCancelMappings(state) {
      state.isEditMapping = false;
      state.currentlyEditing = undefined;
      state.selectShopifyPreference = undefined;
    },

    onDeleteExistingMapping(state, { payload }) {
      const { id, mappingsFor } = payload;

      const index = state.mappings.defaultMappings[mappingsFor].findIndex(
        (mappedItem) => mappedItem.id === id
      );

      if (mappingsFor === "products") {
        state.mappings.defaultMappings[mappingsFor][index] = {
          ...state.mappings.defaultMappings[mappingsFor][index],
          SH_Fields: [],
        };
        state.deletedFortnoxProperties[mappingsFor].forEach((fxProperty) => {
          if (
            fxProperty ===
            state.mappings.defaultMappings[mappingsFor][index].FX_FieldName_CRF
          ) {
            state.mappings.defaultMappings[mappingsFor].splice(index, 1);
          }
        });
      } else {
        state.mappings.defaultMappings[mappingsFor][index] = {
          ...state.mappings.defaultMappings[mappingsFor][index],
          SH_FieldName_CRF: undefined,
          SH_FieldName_HRF: undefined,
          SH_FieldType: undefined,
          SH_Field_Description: undefined,
        };
        if (mappingsFor === "invoice") {
          state.mappings.defaultMappings[mappingsFor][index] = {
            ...state.mappings.defaultMappings[mappingsFor][index],
            SH_Accordion: undefined,
          };
        }
      }

      state.isMappingsSaved[mappingsFor] = false;
    },

    onSaveEditedMappings(state, { payload }) {
      const { id, mappingsFor } = payload;

      if (
        state.selectShopifyPreference !== "" ||
        state.selectShopifyPreference !== undefined
      ) {
        const index = state.mappings.defaultMappings[mappingsFor].findIndex(
          (mappedItem) => mappedItem.id === id
        );

        state.mappings.defaultMappings[mappingsFor][index] = {
          ...state.mappings.defaultMappings[mappingsFor][index],
          SH_FieldName_CRF: state.selectShopifyPreference.CRF,
          SH_FieldName_HRF: state.selectShopifyPreference.HRF,
          SH_FieldType: state.selectShopifyPreference.type,
          SH_Field_Description: state.selectShopifyPreference.description,
        };
        if (mappingsFor === "invoice") {
          state.mappings.defaultMappings[mappingsFor][index] = {
            ...state.mappings.defaultMappings[mappingsFor][index],
            SH_Accordion: state.selectShopifyPreference.accordion,
          };
        }
      }

      state.selectShopifyPreference = undefined;
      state.isEditMapping = false;
      state.currentlyEditing = undefined;
      state.isMappingsSaved[mappingsFor] = false;
    },

    clearDefaultValues(state) {
      state.currentlyEditing = undefined;
      state.isEditMapping = false;
      state.selectShopifyPreference = undefined;
      state.searchInput = "";
      state.searchResultsArray = [];
      state.selectedIndex = 0;
      state.pageCount = 0;
      state.itemOffset = 0;
    },

    checkIsMappingsSaved(state, { payload }) {
      const { mappingsFor } = payload;

      state.isMappingsSaved[mappingsFor] = true;
    },

    validateShopifyProperties(state, { payload }) {
      const { mappingsFor } = payload;
      state.deletedShopifyProperties[mappingsFor] = [];

      const newSet = new Set(
        (mappingsFor === "invoice"
          ? [
              ...state.shopify.shopifyDropdownFields.customer,
              ...state.shopify.shopifyDropdownFields.invoice,
            ]
          : state.shopify.shopifyDropdownFields[mappingsFor]
        ).map((shopifyProperty) => shopifyProperty.CRF)
      );
      if (mappingsFor !== "products") {
        state.mappings.defaultMappings[mappingsFor].forEach((mapping) => {
          if (
            mapping.SH_FieldName_CRF !== undefined &&
            !newSet.has(mapping.SH_FieldName_CRF)
          ) {
            state.deletedShopifyProperties[mappingsFor].push(
              mapping.SH_FieldName_CRF
            );
          }
        });
      } else {
        state.mappings.defaultMappings[mappingsFor].forEach((mapping) => {
          if (mapping.SH_Fields && mapping?.SH_Fields.length > 0) {
            mapping.SH_Fields.forEach((shField) => {
              if (!newSet.has(shField.SH_FieldName_CRF)) {
                state.deletedShopifyProperties[mappingsFor].push(
                  shField.SH_FieldName_CRF
                );
              }
            });
          }
        });
      }
    },
    validateFortnoxProperties(state, { payload }) {
      const { mappingsFor } = payload;

      state.deletedFortnoxProperties[mappingsFor] = [];
      const newSet = new Set(
        state.fortnox.fortnoxDropdownFields[mappingsFor].map(
          (fxProperty) => fxProperty.CRF
        )
      );

      state.mappings.defaultMappings[mappingsFor].forEach((mapping) => {
        if (
          mapping.FX_FieldName_CRF !== undefined &&
          !newSet.has(mapping.FX_FieldName_CRF)
        ) {
          state.deletedFortnoxProperties[mappingsFor].push(
            mapping.FX_FieldName_CRF
          );
        }
      });
    },

    onAddShFieldsToCustomMappings(state, { payload }) {
      const { checked, mappingsFor, mappedItem, selectedValue } = payload;

      const index = state.mappings.defaultMappings[mappingsFor].findIndex(
        (mapped) => mapped.id === mappedItem.id
      );

      if (index !== -1) {
        if (checked === true) {
          state.mappings.defaultMappings[mappingsFor][index].SH_Fields.push({
            SH_FieldName_HRF: selectedValue.HRF,
            SH_FieldName_CRF: selectedValue.CRF,
            SH_FieldType: selectedValue.type,
            SH_Field_Description: selectedValue.description,
            SH_ProductId: selectedValue.productId,
          });
        } else {
          const shIndex = state.mappings.defaultMappings[mappingsFor][
            index
          ].SH_Fields.findIndex(
            (shField) => shField.SH_ProductId === selectedValue.productId
          );

          if (shIndex !== -1) {
            state.mappings.defaultMappings[mappingsFor][index].SH_Fields.splice(
              shIndex,
              1
            );
          }
        }
        state.isMappingsSaved.products = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShopifyFields.pending, (state, action) => {
      if (!state.shopify.loading) {
        state.shopify.loading = true;
      }
    });
    builder.addCase(fetchShopifyFields.fulfilled, (state, action) => {
      if (state.shopify.loading) {
        state.shopify.loading = false;
        state.shopify.shopifyDropdownFields = action.payload;
        state.shopify.error = "";

        if (state.shopify.shopifyDropdownFields.customFields) {
          state.shopify.shopifyDropdownFields.customFields.push({
            HRF: "Do not add it anywhere",
            CRF: "doNotAdd",
            type: "string",
            description: "",
          });
        }
      }
    });
    builder.addCase(fetchShopifyFields.rejected, (state, action) => {
      if (state.shopify.loading) {
        state.shopify.loading = false;
        state.shopify.error = action.error.message ?? "";
        state.shopify.shopifyDropdownFields = {};
      }
    });

    builder.addCase(fetchFortnoxFields.pending, (state, action) => {
      if (!state.fortnox.loading) {
        state.fortnox.loading = true;
      }
    });
    builder.addCase(fetchFortnoxFields.fulfilled, (state, action) => {
      if (state.fortnox.loading) {
        state.fortnox.loading = false;
        state.fortnox.fortnoxDropdownFields = action.payload;
        state.fortnox.error = "";
      }
    });
    builder.addCase(fetchFortnoxFields.rejected, (state, action) => {
      if (state.fortnox.loading) {
        state.fortnox.loading = false;
        state.fortnox.error = action.error.message ?? "";
        state.fortnox.fortnoxDropdownFields = {};
      }
    });

    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      const { mapping } = action.payload;

      const { customer, products, invoice, productFieldMappings } = mapping;

      state.mappings.defaultMappings.customer = customer ?? [];
      state.mappings.defaultMappings.invoice = invoice ?? [];
      state.mappings.defaultMappings.products = products ?? [];
      state.mappings.defaultMappings.productFieldMappings =
        productFieldMappings ?? [];

      const dummyObject = {
        customer: [],
        products: [],
        invoice: [],
        productFieldMappings: [],
      };

      Object.keys(state.mappings.defaultMappings).forEach((category) => {
        state.mappings.defaultMappings[category].forEach((mappedItem) => {
          dummyObject[category].push({
            ...mappedItem,
            id: `${mappedItem.FX_FieldName_CRF}-${category}`,
          });
        });
      });

      state.mappings.defaultMappings = dummyObject;
    });
  },
});

export const {
  checkUnmappedFields,
  setShopifyPreference,
  onEditMappings,
  onSearchMappings,
  onCancelMappings,
  onDeleteExistingMapping,
  onSaveEditedMappings,
  clearDefaultValues,
  checkIsMappingsSaved,
  updateItemOffset,
  updatePageCount,
  updateSelectedIndex,
  updateSlicedMappings,
  validateShopifyProperties,
  validateFortnoxProperties,
  storeActiveAccordion,
  storeActiveAccordionTitle,
  onAddShFieldsToCustomMappings,
} = mappingSlice.actions;

export default mappingSlice.reducer;
