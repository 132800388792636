import { createContext, useState, useContext } from "react";

const NavContext = createContext();

export const useCmsHook = () => {
  const context = useContext(NavContext);
  if (context === undefined) {
    throw new Error("useDirty can only be used inside DirtyProvider");
  }
  return context;
};

export const CmsProvider = ({ children }) => {
  const [cmsInstance, setCmsInstance] = useState(undefined);
  const [isAllowedUser, setIsAllowedUser] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [allowedUsers, setAllowedUsers] = useState([]);

  const cmsHook = {
    cmsInstance,
    setCmsInstance,
    isAllowedUser,
    setIsAllowedUser,
    isEdit,
    setIsEdit,
    allowedUsers,
    setAllowedUsers,
  };
  return <NavContext.Provider value={cmsHook}>{children}</NavContext.Provider>;
};
