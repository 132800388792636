import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  onChangeSettingsActiveTab,
  fetchStatus,
  setShowErrorModal,
} from "../store/slices/preferenceSlice";
import {
  clearDefaultValues,
  fetchShopifyFields,
  fetchFortnoxFields,
} from "../store/slices/mappingSlice";
import { getFortnoxDefaultMappings } from "../store/slices/contactSlice";
import { fetchStages } from "../store/slices/invoiceSlice";

import ContactSync from "./ContactSync";
import InvoiceSync from "./InvoiceSync";
import ProductSync from "./ProductSync";

// CMS Helpers
import { useCmsHook } from "../helpers/cmsHook";
import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

import { Button, Banner } from "@shopify/polaris";
import { Redirect } from "@shopify/app-bridge/actions";
import createApp from "@shopify/app-bridge";

const SyncSettings = () => {
  const tabKeys = Object.freeze({
    contact: "contact",
    invoice: "invoice",
    product: "product",
  });

  const [isLoading, setIsLoading] = useState(false);

  const { cmsInstance, setCmsInstance } = useCmsHook();

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // preference states
  const { settingsActiveTab, isFortnoxConnected } = useSelector(
    (state) => state.preference
  );

  // contact states
  const { isContactsSyncRulesSaved, isContactDefaultMappingsSaved } =
    useSelector((state) => state.contact);

  // invoice states
  const { isInvoiceSyncRulesSaved } = useSelector((state) => state.invoice);

  // product states
  const { isProductSyncRulesSaved } = useSelector((state) => state.product);

  // mapping states
  const {
    isMappingsSaved: {
      customer: isCustomerMappingsSaved,
      invoice: isInvoiceMappingsSaved,
      productFieldMappings: isProductMappingsSaved,
    },
    shopify: { loading: shopifyLoading },
    fortnox: { loading: fortnoxLoading },
  } = useSelector((state) => state.mapping);

  const redirectToConnect = async () => {
    try {
      const config = {
        apiKey: process.env.REACT_APP_SHOPIFY_CLIENT,
        host: new URLSearchParams(window.location.search).get("host"),
      };
      const app = createApp(config);
      const redirect = Redirect.create(app);
      redirect.dispatch(Redirect.Action.APP, "/connect");
    } catch (error) {
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const shop = searchParams.get("shop");

    if (isFortnoxConnected && shop) {
      dispatch(fetchFortnoxFields({ shop }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFortnoxConnected]);

  useEffect(() => {
    const shop = searchParams.get("shop");
    if (shop && isFortnoxConnected) {
      dispatch(getFortnoxDefaultMappings({ shop }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFortnoxConnected]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const shop = searchParams.get("shop");
    if (shop) {
      dispatch(fetchStatus({ shop }));
      dispatch(fetchShopifyFields({ shop }));
      dispatch(fetchStages({ shop }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/syncSettings") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor();
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-container">
      {!cmsInstance ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <>
          {window.location.pathname === "/settings" && (
            <div>
              <CmsEditAndSave />
            </div>
          )}
          {!isFortnoxConnected && (
            <div className="d-flex flex-row align-items-center justify-content-center pt-4">
              <Banner title="Your Fortnox account is not connected please connect your Fortnox account to proceed further">
                <div className="mt-3">
                  <Button
                    plain
                    onClick={() => redirectToConnect()}
                    loading={isLoading}
                  >
                    Connect Fortnox
                  </Button>
                </div>
              </Banner>
            </div>
          )}
          <Container className="py-5">
            <div className="sync-settings-tab-container py-3 px-4">
              <Tabs
                activeKey={settingsActiveTab}
                onSelect={(selectedKey) => {
                  dispatch(clearDefaultValues());
                  dispatch(
                    onChangeSettingsActiveTab({
                      selectedTab: selectedKey,
                      isContactsSyncRulesSaved,
                      isContactDefaultMappingsSaved,
                      isCustomerMappingsSaved,
                      isInvoiceSyncRulesSaved,
                      isInvoiceMappingsSaved,
                      isProductSyncRulesSaved,
                      isProductMappingsSaved,
                      shopifyLoading,
                      fortnoxLoading,
                    })
                  );
                }}
                className="sync-settings-tabs"
              >
                <Tab eventKey={tabKeys.contact} title="Customer">
                  <ContactSync />
                </Tab>
                <Tab eventKey={tabKeys.product} title="Products">
                  <ProductSync />
                </Tab>
                <Tab eventKey={tabKeys.invoice} title="Invoices">
                  <InvoiceSync />
                </Tab>
              </Tabs>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default SyncSettings;
