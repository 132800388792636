import React from "react";
import { Spinner } from "react-bootstrap";

import { useSelector } from "react-redux";

import MappingWindow from "../mapping/MappingWindow";

const FieldMappings = () => {
  const {
    mappings: {
      defaultMappings: { productFieldMappings: mappings },
    },
    shopify: {
      shopifyDropdownFields: { productFieldMappings: shopifyFields },
      loading: shopifyLoading,
    },
    fortnox: {
      fortnoxDropdownFields: { productFieldMappings: fortnoxFields },
      loading: fortnoxLoading,
    },
  } = useSelector((state) => state.mapping);

  return (
    <div>
      {shopifyLoading || fortnoxLoading ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center my-3"
          style={{ height: "40vh" }}
        >
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="md"
          ></Spinner>
        </div>
      ) : (
        <div>
          {fortnoxFields &&
            fortnoxFields.length > 0 &&
            shopifyFields &&
            shopifyFields.length > 0 && (
              <MappingWindow
                mappings={mappings}
                fortnoxFields={fortnoxFields}
                shopifyFields={shopifyFields}
                mappingsFor="productFieldMappings"
                notificationPath="productFieldMappings"
                cmsPath="cmsContent.syncSettings.product.fieldMappings"
              />
            )}
        </div>
      )}
    </div>
  );
};

export default FieldMappings;
