import React, { useEffect } from "react";
import { Card, Col, Container } from "react-bootstrap";

// CMS Helpers
import { useCmsHook } from "../helpers/cmsHook";
import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

function Confirmation() {
  const { cmsInstance, setCmsInstance } = useCmsHook();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.pathname === "/confirmation") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor();
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-container">
      {window.location.pathname === "/confirmation" && <CmsEditAndSave />}
      <Container className="d-flex flex-row justify-content-center py-5">
        <Col lg={10}>
          <Card className="w-100 confirmation-card">
            <Card.Body>
              {cmsInstance &&
                cmsInstance.showRichText("cmsContent.confirmation")}
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </div>
  );
}

export default Confirmation;
