import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { getUrl } from "../../helpers/url";

import { fetchStatus, setShowErrorModal } from "./preferenceSlice";

const initialState = {
  isInvoiceSyncRulesSaved: true,
  preferenceForStages: "fulfillment stage",
  preferenceForInvoice: "draft invoice",
  orderDiscountPreference: undefined,
  shippingDischargePreference: undefined,
  fulfillmentStatusPreference: undefined,
  paymentStatusPreference: undefined,
  attachPDFToInvoice: false,
  stages: {
    loading: false,
    fulfillmentStatus: [],
    paymentStatus: [],
    error: "",
  },
  discount: {
    orderDiscount: false,
    shippingDischarge: false,
  },
};

export const fetchStages = createAsyncThunk(
  "invoice/fetchStages",
  async ({ shop }, { dispatch }) => {
    try {
      const { data: response } = await axios.get(
        getUrl("REACT_APP_GET_STAGES"),
        {
          params: {
            shop,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
      throw error;
    }
  }
);

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    onChangePreferenceForSearchParameters(state, { payload }) {
      state.preferenceForSearchParameters = payload.selected;
      state.isInvoiceSyncRulesSaved = false;
    },

    onChangePreferenceForProducts(state, { payload }) {
      state.preferenceForProducts = payload.selected;
      state.isInvoiceSyncRulesSaved = false;
    },

    onChangePreferenceForStages(state, { payload }) {
      state.preferenceForStages = payload.selected;
      state.isInvoiceSyncRulesSaved = false;
    },

    onChangePreferenceForInvoice(state, { payload }) {
      state.preferenceForInvoice = payload.selected;
      state.isInvoiceSyncRulesSaved = false;
    },

    onCheckAddPDF(state) {
      state.attachPDFToInvoice = !state.attachPDFToInvoice;
      state.isInvoiceSyncRulesSaved = false;
    },

    setIsInvoiceSyncRulesSaved(state) {
      state.isInvoiceSyncRulesSaved = true;
    },

    onChangeInvoiceDropdownFields(state, { payload }) {
      if (payload.selectingFor === "shopifyProductFilterDropdown") {
        state.shopifyProductFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isInvoiceSyncRulesSaved = false;
      } else if (payload.selectingFor === "msbcProductFilterDropdown") {
        state.msbcProductFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isInvoiceSyncRulesSaved = false;
      } else if (payload.selectingFor === "defaultProduct") {
        state.defaultProductPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isInvoiceSyncRulesSaved = false;
      } else if (payload.selectingFor === "fulfillmentStatus") {
        state.fulfillmentStatusPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isInvoiceSyncRulesSaved = false;
      } else if (payload.selectingFor === "paymentStatus") {
        state.paymentStatusPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isInvoiceSyncRulesSaved = false;
      } else if (payload.selectingFor === "orderDiscount") {
        state.orderDiscountPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isInvoiceSyncRulesSaved = false;
      } else if (payload.selectingFor === "shippingDischarge") {
        state.shippingDischargePreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isInvoiceSyncRulesSaved = false;
      }
    },

    onChangeDiscount(state, { payload }) {
      state.discount[payload.selectedOption] =
        !state.discount[payload.selectedOption];
      state.isInvoiceSyncRulesSaved = false;
    },

    clearInvoicePreferences(state) {
      state.preferenceForProducts = "do not generate invoice";
      state.defaultProductPreference = undefined;
      state.fulfillmentStatusPreference = undefined;
      state.paymentStatusPreference = undefined;
      state.attachPDFToInvoice = false;
      state.selectedPipelines = [];
      state.isInvoiceSyncRulesSaved = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStages.pending, (state, action) => {
      if (!state.stages.loading) {
        state.stages.loading = true;
      }
    });
    builder.addCase(fetchStages.fulfilled, (state, action) => {
      if (state.stages.loading) {
        state.stages.loading = false;
        state.stages.fulfillmentStatus = action.payload.fulfillmentStages;
        state.stages.paymentStatus = action.payload.paymentStages;
        state.stages.error = "";
      }
    });
    builder.addCase(fetchStages.rejected, (state, action) => {
      if (state.stages.loading) {
        state.stages.loading = false;
        state.stages.error = action.error.message ?? "";
        state.stages.fulfillmentStatus = [];
        state.stages.paymentStatus = [];
      }
    });

    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      const { preference } = action.payload;

      if (preference && Object.keys(preference).length > 0) {
        const { invoice } = preference;

        if (invoice && Object.keys(invoice).length > 0) {
          if (invoice?.associateInvoiceType) {
            state.preferenceForInvoice = invoice?.associateInvoiceType
              ?.association
              ? "draft invoice"
              : "draft order";
          }

          if (invoice?.triggerSettings) {
            state.fulfillmentStatusPreference = invoice?.triggerSettings[0]
              ?.fulfillmentStatus?.value
              ? invoice.triggerSettings[0].fulfillmentStatus
              : undefined;
            state.paymentStatusPreference = invoice?.triggerSettings[0]
              ?.financialStatus?.value
              ? invoice.triggerSettings[0].financialStatus
              : undefined;
          }
          state.preferenceForStages =
            invoice?.triggerSettings[0]?.fulfillmentStatus?.value ===
            "fulfilled"
              ? "fulfillment stage"
              : "payment stage";

          if (invoice?.extraData) {
            state.discount.orderDiscount = invoice?.extraData?.orderDiscount;
            state.discount.shippingDischarge =
              invoice?.extraData?.shippingDischarge;

            state.orderDiscountPreference = invoice.extraData
              ?.orderDiscountProduct?.productNumber?.value
              ? invoice.extraData?.orderDiscountProduct?.productNumber
              : undefined;

            state.shippingDischargePreference = invoice.extraData
              ?.shippingDischargeProduct?.productNumber?.value
              ? invoice.extraData?.shippingDischargeProduct?.productNumber
              : undefined;
          }
        }
      }
    });
  },
});

export const {
  onChangePreferenceForSearchParameters,
  onChangeInvoiceDropdownFields,
  onChangePreferenceForProducts,
  onCheckAddPDF,
  setIsInvoiceSyncRulesSaved,
  clearInvoicePreferences,
  onChangePreferenceForStages,
  onChangePreferenceForInvoice,
  onChangeDiscount,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
