import { useState } from "react";
import { Button, Alert, CloseButton } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useCmsHook } from "./cmsHook";
import { CmsConstructor } from "./cmsConstructor";

const CmsEditAndSave = () => {
  const [closeBtn, setCloseBtn] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const {
    cmsInstance,
    isEdit,
    allowedUsers,
    setIsAllowedUser,
    isAllowedUser,
    setIsEdit,
    setCmsInstance,
  } = useCmsHook();

  const [searchParams] = useSearchParams();
  const shop = searchParams.get("shop");

  let isUserAllowed = false;
  for (const user of allowedUsers) {
    if (user.shop === shop) {
      isUserAllowed = true;
      break;
    }
  }

  if (isUserAllowed !== isAllowedUser && closeBtn === false) {
    setIsAllowedUser(isUserAllowed);
  }

  const handleEdit = () => {
    setIsEdit(true);
    setShowSaveBtn(true);
  };

  const handleClose = () => {
    setIsAllowedUser(false);
    setCloseBtn(true);
    setIsEdit(false);
  };

  const handlePreview = () => {
    setIsEdit(false);
  };

  const handleSaveChanges = () => {
    cmsInstance.saveContentChanges();
    setIsEdit(false);
    setShowSaveBtn(false);
  };

  const handleCancel = async () => {
    setIsEdit(false);
    setShowSaveBtn(false);
    const pmCmsInstance = await CmsConstructor();
    setCmsInstance(pmCmsInstance);
  };

  return (
    <>
      {isAllowedUser && (
        <div
          className="d-flex flex-row justify-content-center mb-0"
          style={{ backgroundColor: "#fafbfb" }}
        >
          <Alert className="d-flex flex-row justify-content-between align-items-center col-lg-8 mb-0">
            <div>
              <p className="text-center mt-2">
                To change the content click on the edit button below.
              </p>
            </div>
            <div className="d-flex flex-row justify-content-end me-3 mb-3">
              {window.location.pathname !== "/edit-notifications" && (
                <Button
                  variant="link"
                  href={`/edit-notifications?shop=${shop}`}
                  target="_blank"
                  className="me-2"
                >
                  Edit notifications
                </Button>
              )}
              {showSaveBtn && (
                <Button onClick={() => handleSaveChanges()}>Save</Button>
              )}
              {!isEdit && (
                <Button className="ms-2" onClick={() => handleEdit()}>
                  Edit
                </Button>
              )}

              {isEdit && (
                <Button
                  variant="secondary"
                  onClick={() => handlePreview()}
                  className="ms-2"
                >
                  Preview
                </Button>
              )}
              {isEdit && (
                <Button
                  className="px-3 ms-2"
                  variant="outline-danger"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              )}
              <div className="ms-2 d-flex flex-column justify-content-center">
                <CloseButton onClick={handleClose} />
              </div>
            </div>
          </Alert>
        </div>
      )}
    </>
  );
};

export default CmsEditAndSave;
