import React, { useEffect, useState } from "react";
import { Col, Table, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Button } from "@shopify/polaris";

import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { BsArrowRightShort } from "react-icons/bs";

import axios from "axios";

import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/delete.svg";

import { useCmsHook } from "../../helpers/cmsHook";

import {
  checkUnmappedFields,
  onSearchMappings,
  onDeleteExistingMapping,
  onSaveEditedMappings,
  onEditMappings,
  onCancelMappings,
  validateShopifyProperties,
  validateFortnoxProperties,
  checkIsMappingsSaved,
} from "../../store/slices/mappingSlice";

import MappingDropdown from "./MappingDropdown";
import SearchResultsNotFound from "./SearchResultsNotFound";
import Pagination from "./Pagination";
import CreateNewProperty from "./CreateNewProperty";
import CustomProductMappingDropdown from "./CustomProductMappingDropdown";

import { getUrl } from "../../helpers/url";
import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../../store/slices/preferenceSlice";

const MappingWindow = ({
  mappings,
  fortnoxFields,
  shopifyFields,
  mappingsFor,
  shopifyCustomerFields,
  notificationPath,
  cmsPath,
}) => {
  const { cmsInstance } = useCmsHook();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  // mapping states
  const {
    searchInput,
    searchResultsArray,
    currentlyEditing,
    selectShopifyPreference,
    isEditMapping,
    slicedMappings,
    deletedShopifyProperties,
    deletedFortnoxProperties,
  } = useSelector((state) => state.mapping);

  const mappedList =
    (mappingsFor === "products" && mappings.length > 10
      ? slicedMappings
      : mappings
    )?.map((mappedItem) => {
      if (
        searchResultsArray.includes(mappedItem.id) ||
        searchInput.length === 0 ||
        !searchInput
      )
        return renderMappings({
          currentlyEditing,
          mappedItem,
          shopifyFields,
          selectShopifyPreference,
          isEditMapping,
          mappingsFor,
          mappings: mappingsFor === "products" ? slicedMappings : mappings,
          dispatch,
          deletedShopifyProperties,
          deletedFortnoxProperties,
          shopifyCustomerFields,
        });
      return null;
    }) ?? [];

  useEffect(() => {
    if (fortnoxFields && fortnoxFields.length > 0 && mappings) {
      dispatch(checkUnmappedFields({ mappingsFor }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappings, fortnoxFields, mappingsFor]);

  useEffect(() => {
    if (mappings && shopifyFields && shopifyFields.length > 0) {
      dispatch(validateShopifyProperties({ mappingsFor }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopifyFields, mappingsFor, mappings]);

  useEffect(() => {
    if (mappings && fortnoxFields && fortnoxFields.length > 0) {
      dispatch(validateFortnoxProperties({ mappingsFor }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fortnoxFields, mappingsFor, mappings]);

  return (
    <div className="my-4 mapping-window">
      {cmsInstance && cmsInstance.showRichText(cmsPath)}
      <div className="my-3 d-flex justify-content-between">
        <div className="dropdown-search-container px-2 w-25">
          <span>
            <GoSearch size={20} className="me-2" />
          </span>
          <input
            type="search"
            value={searchInput}
            placeholder="Search"
            onChange={(e) =>
              dispatch(
                onSearchMappings({ enteredInput: e.target.value, mappingsFor })
              )
            }
            className="search-input"
          />
        </div>
        {mappingsFor !== "products" && (
          <div>
            <CreateNewProperty mappingsFor={mappingsFor} />
          </div>
        )}
      </div>

      {searchResultsArray.length === 0 && searchInput ? (
        <SearchResultsNotFound />
      ) : (
        <Table responsive className="mapping-table">
          <thead className="mapping-table-header">
            <tr>
              <th
                style={{ width: "350px", fontFamily: "Inter" }}
                className="ps-4"
              >
                Fortnox
              </th>
              <th style={{ width: "200px" }}></th>
              <th
                style={{
                  width: "350px",
                  fontFamily: "Inter",
                  paddingLeft: `${mappingsFor === "products" ? "30px" : ""}`,
                }}
                className="py-2"
              >
                Shopify
              </th>
              <th
                className="pe-4"
                style={{
                  textAlign: "center",
                  width: "100px",
                  fontFamily: "Inter",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>

          <tbody className="mapping-table-body">{mappedList}</tbody>
        </Table>
      )}

      <div className="d-flex flex-row justify-content-end mt-4">
        {(deletedShopifyProperties[mappingsFor] &&
          deletedShopifyProperties[mappingsFor].length > 0) ||
        (deletedFortnoxProperties[mappingsFor] &&
          deletedFortnoxProperties[mappingsFor].length > 0) ? (
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip style={{ fontSize: "12px" }}>
                {deletedShopifyProperties[mappingsFor] &&
                deletedShopifyProperties[mappingsFor].length > 0
                  ? "Please remap the deleted Shopify fields."
                  : "You have deleted the product in the Fortnox please delete the mapped deleted product and save the mappings."}
              </Tooltip>
            }
          >
            <div>
              <Button
                style={{ opacity: 0.8, cursor: "not-allowed" }}
                className="sync-settings-button"
                disabled={isEditMapping}
              >
                Save changes
              </Button>
            </div>
          </OverlayTrigger>
        ) : (
          <Button
            className="sync-settings-button"
            loading={isLoading}
            primary
            onClick={() =>
              onSaveMappings({
                setIsLoading,
                searchParams,
                mappings,
                mappingsFor,
                dispatch,
                notificationPath,
                cmsInstance,
              })
            }
          >
            Save changes
          </Button>
        )}
      </div>

      {mappingsFor === "products" && mappings.length > 10 && (
        <Pagination mappingsFor={mappingsFor} mappings={mappings} />
      )}
    </div>
  );
};

export default MappingWindow;

const renderMappings = ({
  currentlyEditing,
  mappedItem,
  shopifyFields,
  selectShopifyPreference,
  isEditMapping,
  mappingsFor,
  mappings,
  dispatch,
  deletedShopifyProperties,
  deletedFortnoxProperties,
  shopifyCustomerFields,
}) => {
  if (mappedItem.id === currentlyEditing && isEditMapping) {
    return (
      <tr style={{ verticalAlign: "middle" }} key={mappedItem.id}>
        <td className="ps-4" style={{ fontFamily: "Inter" }}>
          {mappedItem.FX_FieldName_HRF}
        </td>
        <td style={{ textAlign: "center" }}>
          <BsArrowRightShort
            style={{
              transform: "scaleX(-1)",
            }}
            className="mt-auto"
            size={25}
          />
        </td>
        <td>
          <Col lg={9}>
            <MappingDropdown
              mappings={mappings}
              shopifyDropdownFields={shopifyFields}
              mappedItem={mappedItem}
              mappingsFor={mappingsFor}
              shopifyCustomerFields={shopifyCustomerFields}
            />
          </Col>
        </td>
        <td style={{ textAlign: "center" }}>
          <div>
            {selectShopifyPreference && (
              <button
                onClick={() => {
                  dispatch(
                    onSaveEditedMappings({ id: mappedItem.id, mappingsFor })
                  );
                }}
                className="save-mapping-icon"
                style={{ marginRight: "6px" }}
              >
                <FaCheck size={14} />
              </button>
            )}
            <button
              onClick={() => {
                dispatch(onCancelMappings());
              }}
              className="save-mapping-icon"
              style={{ marginRight: "14px" }}
            >
              <ImCross size={14} />
            </button>
          </div>
        </td>
      </tr>
    );
  } else {
    return (
      <tr style={{ verticalAlign: "middle" }} key={mappedItem.id}>
        <td className="ps-4">
          {mappingsFor === "products" ? (
            <span>
              <span style={{ fontFamily: "Inter" }}>
                {mappedItem.FX_FieldName_HRF}
              </span>
              <br></br>
              <span
                style={{
                  color: "#7a91ab",
                  fontSize: "12px",
                  fontFamily: "Inter",
                }}
              >
                {mappedItem.FX_Field_Description}
              </span>
            </span>
          ) : (
            <span>
              <span style={{ fontFamily: "Inter" }}>
                {mappedItem.FX_FieldName_HRF}
              </span>
              <br></br>
              <span
                style={{
                  color: "#7a91ab",
                  fontSize: "12px",
                  fontFamily: "Inter",
                }}
              >
                {mappedItem.FX_Field_Description}
              </span>
            </span>
          )}
          <br></br>
          {mappedItem.FX_FieldName_CRF !== undefined &&
            deletedFortnoxProperties[mappingsFor].includes(
              mappedItem.FX_FieldName_CRF
            ) && <span className="error-text">(property deleted)</span>}
        </td>
        <td style={{ textAlign: "center" }}>
          <BsArrowRightShort
            style={{
              transform: "scaleX(-1)",
            }}
            className="mt-auto"
            size={25}
          />
        </td>
        {mappingsFor === "products" ? (
          <td>
            {shopifyFields && (
              <CustomProductMappingDropdown
                mappedItem={mappedItem}
                mappings={mappings}
                shopifyDropdownFields={shopifyFields}
                mappingsFor={mappingsFor}
                deletedShopifyFields={deletedShopifyProperties[mappingsFor]}
              />
            )}
          </td>
        ) : (
          <td>
            {mappedItem.SH_FieldName_HRF === undefined ? (
              <span
                style={{
                  cursor: "pointer",
                  color: "#7a91ab",
                  fontFamily: "Inter",
                }}
              >
                Not Mapped
              </span>
            ) : (
              <span>
                <span style={{ fontFamily: "Inter" }}>
                  {mappedItem.SH_FieldName_HRF}
                </span>
                <br></br>
                <span
                  style={{
                    color: "#7a91ab",
                    fontSize: "12px",
                    fontFamily: "Inter",
                  }}
                >
                  {mappedItem.SH_Field_Description}
                </span>
              </span>
            )}
            <br />
            {mappedItem.SH_FieldName_CRF !== undefined &&
              deletedShopifyProperties[mappingsFor].includes(
                mappedItem.SH_FieldName_CRF
              ) && <span className="error-text">(property deleted)</span>}
          </td>
        )}

        <td style={{ textAlign: "center" }}>
          <div>
            {mappingsFor !== "products" && (
              <button
                onClick={() => {
                  dispatch(
                    onEditMappings({
                      id: mappedItem.id,
                    })
                  );
                }}
                className="save-mapping-icon"
                disabled={deletedFortnoxProperties[mappingsFor].includes(
                  mappedItem.FX_FieldName_CRF
                )}
                style={{
                  marginRight: "6px",
                  opacity: `${
                    deletedFortnoxProperties[mappingsFor].includes(
                      mappedItem.FX_FieldName_CRF
                    )
                      ? "0.5"
                      : ""
                  }`,
                  cursor: `${
                    deletedFortnoxProperties[mappingsFor].includes(
                      mappedItem.FX_FieldName_CRF
                    )
                      ? "not-allowed"
                      : ""
                  }`,
                }}
              >
                <img src={editIcon} alt="edit icon" />
              </button>
            )}

            <button
              disabled={
                mappedItem.FX_Field_Required === true ||
                (mappingsFor !== "products" &&
                  mappedItem.SH_FieldName_CRF === undefined) ||
                (mappingsFor === "products" &&
                  ((mappedItem.SH_Fields &&
                    mappedItem.SH_Fields.length === 0) ||
                    !mappedItem?.SH_Fields))
              }
              onClick={() =>
                dispatch(
                  onDeleteExistingMapping({ id: mappedItem.id, mappingsFor })
                )
              }
              className="save-mapping-icon"
              style={{
                marginRight: "14px",
                opacity: `${
                  mappedItem.FX_Field_Required === true ||
                  (mappingsFor !== "products" &&
                    mappedItem.SH_FieldName_CRF === undefined) ||
                  (mappingsFor === "products" &&
                    ((mappedItem.SH_Fields &&
                      mappedItem.SH_Fields.length === 0) ||
                      !mappedItem?.SH_Fields))
                    ? "0.5"
                    : ""
                }`,
                cursor: `${
                  mappedItem.FX_Field_Required === true ||
                  (mappingsFor !== "products" &&
                    mappedItem.SH_FieldName_CRF === undefined) ||
                  (mappingsFor === "products" &&
                    ((mappedItem.SH_Fields &&
                      mappedItem.SH_Fields.length === 0) ||
                      !mappedItem?.SH_Fields))
                    ? "not-allowed"
                    : ""
                }`,
              }}
            >
              <img src={deleteIcon} alt="delete icon" />
            </button>
          </div>
        </td>
      </tr>
    );
  }
};

export const onSaveMappings = async ({
  setIsLoading,
  searchParams,
  mappings,
  mappingsFor,
  dispatch,
  notificationPath,
  cmsInstance,
}) => {
  try {
    setIsLoading(true);
    const shop = searchParams.get("shop");

    const validMappings = [];
    mappings.forEach((mappedItem) => {
      let newMapping = {};
      if (
        mappingsFor !== "products" &&
        mappedItem.SH_FieldName_CRF !== undefined
      ) {
        newMapping = {
          FX_FieldName_CRF: mappedItem.FX_FieldName_CRF,
          SH_FieldName_HRF: mappedItem.SH_FieldName_HRF,
          SH_FieldName_CRF: mappedItem.SH_FieldName_CRF,
          SH_Field_Description: mappedItem.SH_Field_Description,
          FX_Field_Description: mappedItem.FX_Field_Description,
          FX_FieldType: mappedItem.FX_FieldType,
          SH_FieldType: mappedItem.SH_FieldType,
          FX_FieldName_HRF: mappedItem.FX_FieldName_HRF,
        };
        if (mappedItem.FX_Field_Required) {
          newMapping.FX_Field_Required = mappedItem.FX_Field_Required;
        }
        if (mappingsFor === "invoice") {
          newMapping.Accordion = mappedItem.SH_Accordion;
        }
      } else if (
        mappingsFor === "products" &&
        mappedItem.SH_Fields &&
        mappedItem.SH_Fields.length > 0
      ) {
        newMapping = {
          FX_FieldName_CRF: mappedItem.FX_FieldName_CRF,
          FX_FieldName_HRF: mappedItem.FX_FieldName_HRF,
          FX_Field_Description: mappedItem.FX_Field_Description,
          FX_FieldType: mappedItem.FX_FieldType,
          SH_Fields: mappedItem.SH_Fields,
        };
        if (mappedItem.FX_Field_Required) {
          newMapping.FX_Field_Required = mappedItem.FX_Field_Required;
        }
      }
      if (Object.keys(newMapping).length > 0) {
        validMappings.push(newMapping);
      }
    });
    await axios.post(getUrl("REACT_APP_SAVE_MAPPINGS"), {
      shop,
      mappings: validMappings,
      mappingObj: mappingsFor,
    });

    dispatch(checkIsMappingsSaved({ mappingsFor }));

    dispatch(
      setShowSuccessModal({
        message:
          cmsInstance &&
          cmsInstance.sendData().cmsContent.notifications.syncSettings
            .successContent[notificationPath].message,
      })
    );
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;

    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};
