import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";

import SetupGuideHeader from "../components/SetupGuideHeader";

import { useDispatch } from "react-redux";

import { CmsConstructor } from "../helpers/cmsConstructor";
import { useCmsHook } from "../helpers/cmsHook";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

const SetupGuide = () => {
  const { cmsInstance, setCmsInstance } = useCmsHook();
  const dispatch = useDispatch();

  useEffect(() => {
    const cmsInstance = async () => {
      const cmsObj = await CmsConstructor(dispatch);
      setCmsInstance(cmsObj);
    };
    cmsInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="setup-guide-page">
      <SetupGuideHeader />
      {!cmsInstance ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex flex-column align-items mt-5">
            <CmsEditAndSave />
          </div>
          <div className="container p-5">
            <div className="setup-guide-main-content d-flex flex-column justify-content-center align-items-center">
              <div className="col-md-11 col-12">
                <div className="setup-guide-content-container">
                  {cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.setupGuide",
                      "setup-guide-page-content"
                    )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SetupGuide;
