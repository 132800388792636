import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import {
  Row,
  Col,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Button } from "@shopify/polaris";

import {
  onChangePreferenceForSearchParameters,
  onChangePreferenceForProducts,
  onChangePreferenceForCreatingProduct,
  setIsProductSyncRulesSaved,
} from "../../store/slices/productSlice";

import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../../store/slices/preferenceSlice";

import FieldsDropdown from "../FieldsDropdown";
import MappingWindow from "../mapping/MappingWindow";

import { checkCountForProductMapped } from "../invoices/SyncRules";

import { getUrl } from "../../helpers/url";

import { useCmsHook } from "../../helpers/cmsHook";

const SyncRules = () => {
  const dispatch = useDispatch();

  const { cmsInstance } = useCmsHook();

  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const {
    shopifyProductFilterPreference,
    fortnoxProductFilterPreference,
    preferenceForSearchParameters,
    preferenceForProducts,
    defaultProductPreference,
    preferenceForCreatingProduct,
    defaultProductForCreating,
  } = useSelector((state) => state.product);

  const {
    shopify: {
      loading: shopifyLoading,
      shopifyDropdownFields: {
        products: shopifyProducts,
        productFilterProperties: shopifyProductSearchFields,
      },
    },
    fortnox: {
      loading: fortnoxLoading,
      fortnoxDropdownFields: {
        products: fortnoxProducts,
        productFields: fortnoxProductSearchFields,
      },
    },
    mappings: {
      defaultMappings: { products: productMappings },
    },
    isMappingsSaved: { products: isMappingsSaved },
  } = useSelector((state) => state.mapping);

  return (
    <div>
      {fortnoxLoading || shopifyLoading || !cmsInstance ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center my-3"
          style={{ height: "40vh" }}
        >
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="md"
          ></Spinner>
        </div>
      ) : (
        <div className="px-3">
          <div className="sync-settings-card">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.product.syncRules.section1.text"
              )}
            <div className="mt-3">
              <Form.Check
                type="radio"
                id="selectSearchParameters"
                name="productSearchParameters"
                value="selectSearchParameters"
                label="Select search parameters"
                className="radio-button my-2"
                checked={
                  preferenceForSearchParameters === "selectSearchParameters"
                }
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForSearchParameters({
                      selected: e.target.value,
                    })
                  )
                }
              />
              {preferenceForSearchParameters === "selectSearchParameters" && (
                <div
                  style={{ marginLeft: "25px", marginRight: "25px" }}
                  className="mt-2"
                >
                  {preferenceForSearchParameters === "selectSearchParameters" &&
                    fortnoxProductSearchFields &&
                    shopifyProductSearchFields &&
                    fortnoxProductSearchFields.length > 0 &&
                    shopifyProductSearchFields.length > 0 && (
                      <Row>
                        {cmsInstance &&
                          cmsInstance.showRichText(
                            "cmsContent.syncSettings.product.syncRules.section1.searchParameters"
                          )}
                        <Col lg={3} md={5}>
                          <p className="dropdown-header">Shopify</p>
                          <FieldsDropdown
                            fieldsData={shopifyProductSearchFields}
                            dropdownFor="shopifyProductFilterDropdown"
                            selectedPreference={shopifyProductFilterPreference}
                          />
                        </Col>
                        <Col lg={3} md={5}>
                          <p className="dropdown-header">Fortnox</p>
                          <FieldsDropdown
                            fieldsData={fortnoxProductSearchFields}
                            dropdownFor="fortnoxProductFilterDropdown"
                            selectedPreference={fortnoxProductFilterPreference}
                          />
                        </Col>
                      </Row>
                    )}
                </div>
              )}
            </div>
            <div className="mt-2">
              <Form.Check
                type="radio"
                id="setCustomMappings"
                name="productSearchParameters"
                value="setCustomMappings"
                label="Set custom mappings"
                className="radio-button"
                checked={preferenceForSearchParameters === "setCustomMappings"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForSearchParameters({
                      selected: e.target.value,
                    })
                  )
                }
              />
              {preferenceForSearchParameters === "setCustomMappings" && (
                <div>
                  {fortnoxProducts &&
                  fortnoxProducts.length > 0 &&
                  shopifyProducts &&
                  shopifyProducts.length > 0 ? (
                    <MappingWindow
                      mappings={productMappings}
                      fortnoxFields={fortnoxProducts}
                      shopifyFields={shopifyProducts}
                      mappingsFor="products"
                      notificationPath="customProductMappings"
                      cmsPath="cmsContent.syncSettings.product.syncRules.section1.productFieldMappings"
                    />
                  ) : (
                    <div className="search-not-found-container py-3 text-center">
                      <p>
                        Products not present either in Shopify or in Fortnox.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="sync-settings-card">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.product.syncRules.section2.text"
              )}
            <div className="mt-3">
              <Form.Check
                key="doNotGenerateInvoice"
                type="radio"
                id="doNotGenerateInvoice"
                name="productRadioButton"
                value="doNotGenerateInvoice"
                label="Don't generate invoice"
                className="radio-button my-2"
                checked={preferenceForProducts === "doNotGenerateInvoice"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForProducts({ selected: e.target.value })
                  )
                }
              />
              {preferenceForProducts === "doNotGenerateInvoice" && (
                <div
                  style={{
                    marginLeft: "21px",
                    marginRight: "21px",
                  }}
                >
                  <Row>
                    {cmsInstance &&
                      cmsInstance.showRichText(
                        "cmsContent.syncSettings.product.syncRules.section2.doNotGenerateInvoice"
                      )}
                  </Row>
                </div>
              )}
            </div>
            <div className="mt-2">
              <Form.Check
                key="useDefaultProduct"
                type="radio"
                id="useDefaultProduct"
                name="productRadioButton"
                value="useDefaultProduct"
                label="Use a pre-chosen product"
                className="radio-button my-2"
                checked={preferenceForProducts === "useDefaultProduct"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForProducts({ selected: e.target.value })
                  )
                }
              />
              {preferenceForProducts === "useDefaultProduct" && (
                <div
                  style={{ marginLeft: "25px", marginRight: "25px" }}
                  className="mt-2"
                >
                  {fortnoxProducts && fortnoxProducts.length > 0 ? (
                    <Row>
                      {cmsInstance &&
                        cmsInstance.showRichText(
                          "cmsContent.syncSettings.product.syncRules.section2.defaultProduct"
                        )}
                      <Col lg={3} md={5}>
                        <p className="dropdown-header">Select Product</p>
                        <FieldsDropdown
                          fieldsData={fortnoxProducts}
                          dropdownFor="defaultProduct"
                          selectedPreference={defaultProductPreference}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <div className="search-not-found-container py-3 text-center">
                      <p>No products present in Fortnox.</p>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mt-2">
              <Form.Check
                key="findCreateProduct"
                type="radio"
                id="findCreateProduct"
                name="productRadioButton"
                value="findCreateProduct"
                label="Create new product"
                className="radio-button my-2"
                checked={preferenceForProducts === "findCreateProduct"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForProducts({ selected: e.target.value })
                  )
                }
              />
              {preferenceForProducts === "findCreateProduct" && (
                <div
                  style={{ marginLeft: "25px", marginRight: "25px" }}
                  className="mt-2"
                >
                  <Row>
                    {cmsInstance &&
                      cmsInstance.showRichText(
                        "cmsContent.syncSettings.product.syncRules.section2.findCreateProduct"
                      )}
                  </Row>
                </div>
              )}
              {preferenceForProducts === "findCreateProduct" && (
                <div
                  style={{ marginLeft: "25px", marginRight: "25px" }}
                  className="mt-2"
                >
                  <div>
                    <Form.Check
                      type="checkbox"
                      id="createProduct"
                      label="You have custom products in your orders"
                      checked={preferenceForCreatingProduct}
                      onChange={(e) =>
                        dispatch(onChangePreferenceForCreatingProduct())
                      }
                    />
                    {preferenceForCreatingProduct && (
                      <div
                        style={{ marginLeft: "25px", marginRight: "25px" }}
                        className="mt-2"
                      >
                        {fortnoxProducts && fortnoxProducts.length > 0 ? (
                          <Row>
                            <Col lg={3} md={5}>
                              <p className="dropdown-header">Select Product</p>
                              <FieldsDropdown
                                fieldsData={fortnoxProducts}
                                dropdownFor="product"
                                selectedPreference={defaultProductForCreating}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <div className="search-not-found-container py-3 text-center">
                            <p>No products present in Fortnox.</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end mt-4 mb-2">
            {(preferenceForProducts === "useDefaultProduct" &&
              !defaultProductPreference) ||
            (preferenceForCreatingProduct === true &&
              !defaultProductForCreating) ||
            (preferenceForSearchParameters === "selectSearchParameters" &&
              (!shopifyProductFilterPreference ||
                !fortnoxProductFilterPreference)) ||
            (preferenceForSearchParameters === "setCustomMappings" &&
              (checkCountForProductMapped({ mappings: productMappings }) ===
                0 ||
                (checkCountForProductMapped({ mappings: productMappings }) >
                  0 &&
                  !isMappingsSaved))) ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip>
                    {showToolTipText(
                      preferenceForProducts,
                      defaultProductPreference,
                      preferenceForCreatingProduct,
                      defaultProductForCreating,
                      preferenceForSearchParameters,
                      shopifyProductFilterPreference,
                      fortnoxProductFilterPreference,
                      productMappings,
                      isMappingsSaved
                    )}
                  </Tooltip>
                }
              >
                <div>
                  <Button
                    className="sync-settings-button"
                    style={{ cursor: "not-allowed" }}
                    disabled
                  >
                    Save changes
                  </Button>
                </div>
              </OverlayTrigger>
            ) : (
              <Button
                className="sync-settings-button"
                disabled={isLoading}
                primary
                loading={isLoading}
                onClick={() => {
                  onSaveProductSyncRules({
                    setIsLoading,
                    searchParams,
                    dispatch,
                    cmsInstance,
                    preferenceForProducts,
                    defaultProductPreference,
                    preferenceForCreatingProduct,
                    defaultProductForCreating,
                    preferenceForSearchParameters,
                    shopifyProductFilterPreference,
                    fortnoxProductFilterPreference,
                    setIsProductSyncRulesSaved,
                  });
                }}
              >
                Save changes
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SyncRules;

export const onSaveProductSyncRules = async ({
  setIsLoading,
  searchParams,
  dispatch,
  cmsInstance,
  preferenceForProducts,
  defaultProductPreference,
  preferenceForCreatingProduct,
  defaultProductForCreating,
  preferenceForSearchParameters,
  shopifyProductFilterPreference,
  fortnoxProductFilterPreference,
}) => {
  try {
    setIsLoading(true);
    const shop = searchParams.get("shop");
    await axios.post(getUrl("REACT_APP_SAVE_PREFERENCES"), {
      shop,
      preferences: {
        product: {
          generateInvoice: {
            create:
              preferenceForProducts === "doNotGenerateInvoice" ||
              preferenceForProducts === "findCreateProduct"
                ? false
                : true,
            mapping: {
              fortnoxDefaultProduct:
                preferenceForProducts === "useDefaultProduct" &&
                defaultProductPreference?.value
                  ? defaultProductPreference
                  : {},
            },
          },
          createProduct: {
            create:
              preferenceForProducts === "findCreateProduct" ? true : false,
            customProduct: {
              useFxDefaultProduct:
                preferenceForProducts === "findCreateProduct" &&
                preferenceForCreatingProduct
                  ? true
                  : false,
              mapping: {
                fortnoxDefaultProduct:
                  preferenceForCreatingProduct &&
                  preferenceForProducts === "findCreateProduct" &&
                  defaultProductForCreating?.value
                    ? defaultProductForCreating
                    : {},
              },
            },
          },
          productSearchParameter:
            preferenceForSearchParameters === "selectSearchParameters"
              ? true
              : false,
          filterOptions: {
            filterSHProperty: shopifyProductFilterPreference,
            filterFxProperty: fortnoxProductFilterPreference,
          },
        },
      },
      preferenceType: "product",
    });
    dispatch(setIsProductSyncRulesSaved());

    dispatch(
      setShowSuccessModal({
        message:
          cmsInstance.sendData().cmsContent.notifications.syncSettings
            .successContent.productSyncRules.message,
      })
    );
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};

const showToolTipText = (
  preferenceForProducts,
  defaultProductPreference,
  preferenceForCreatingProduct,
  defaultProductForCreating,
  preferenceForSearchParameters,
  shopifyProductFilterPreference,
  fortnoxProductFilterPreference,
  productMappings,
  isMappingsSaved
) => {
  if (
    preferenceForProducts === "useDefaultProduct" &&
    !defaultProductPreference
  ) {
    return "Please select the default product for using it as default product";
  } else if (
    preferenceForCreatingProduct === true &&
    !defaultProductForCreating
  ) {
    return "Please select the default product for find/create product";
  } else if (
    preferenceForSearchParameters === "selectSearchParameters" &&
    !shopifyProductFilterPreference
  ) {
    return "Please select shopify search parameter";
  } else if (
    preferenceForSearchParameters === "selectSearchParameters" &&
    !fortnoxProductFilterPreference
  ) {
    return "Please select Fortnox search parameter";
  } else if (
    preferenceForSearchParameters === "setCustomMappings" &&
    checkCountForProductMapped({ mappings: productMappings }) === 0
  ) {
    return "Please map at least one product in the mapping window";
  } else if (
    preferenceForSearchParameters === "setCustomMappings" &&
    checkCountForProductMapped({ mappings: productMappings }) &&
    !isMappingsSaved
  ) {
    return "Please save the product mappings";
  }
};
