import axios from "axios";
import Cms from "./Cms";
import { toast } from "react-toastify";

import { getCmsUrl } from "./url";

// this is the constructor that takes appName as the argument and fetch the data
export async function CmsConstructor() {
  try {
    const { data } = await axios.get(getCmsUrl("REACT_APP_GET_CMS_CONTENT"));

    return {
      // This function is used to make the data available for all the files
      sendData: function () {
        return data;
      },

      // this will return the PmCms component
      showRichText: function (path, cssClassName) {
        return <Cms path={path} cssName={cssClassName} data={data} />;
      },

      // this will update the content which we have in the data variable it will take object path and edited value as arguments
      onChangeContent: function (
        path,
        editedValue,
        editNotificationsPathname,
        notModal = true
      ) {
        let updatedContent = editedValue;
        if (editNotificationsPathname === "/edit-notifications" && notModal) {
          const parser = new DOMParser();
          const parsedContent = parser.parseFromString(
            editedValue,
            "text/html"
          );
          updatedContent = parsedContent.body.textContent;
        }
        const properties = path.split(".");
        let current = data;
        for (let i = 0; i < properties.length - 1; i++) {
          const property = properties[i];
          current = current[property];
        }
        const lastProperty = properties[properties.length - 1];
        current[lastProperty] = updatedContent;
      },

      // this function is used to save the changes into the database
      saveContentChanges: async function () {
        try {
          await axios.post(getCmsUrl("REACT_APP_SAVE_CMS_CONTENT"), {
            appSlug: "shopifyFortnox",
            data: data,
          });
          toast.success("Content changes saved successfully", {
            className: "react-toast-success",
          });
        } catch (error) {
          let errorMessage;
          if (error.response && error.response.data.message)
            errorMessage = error.response.data.message;
          else errorMessage = error.message;
          toast.error(errorMessage, { className: "react-toast-error" });
        }
      },
    };
  } catch (error) {
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    toast.error(errorMessage, { className: "react-toast-error" });
  }
}
